// 앵귤러 머터리얼용 스타일
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Sharp:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

@function material-icon-settings($fill: 0, $wght: 400, $grad: 0, $opsz: 24) {
  @return 'FILL' #{$fill}, 'wght' #{$wght}, 'GRAD' #{$grad}, 'opsz' #{$opsz};
}

.material-symbols-rounded {
  font-variation-settings: material-icon-settings();
}

.material-symbols-sharp {
  font-variation-settings: material-icon-settings();
}
