.gloria-g_content {
  --color-primary: white;
  --color-secondary: #c6b288;
  --color-title-text: #7a653a;
  --color-background-1: #979797;

  // body {
  //   font-family: '游明朝', YuMincho, 'ヒラギノ明朝 ProN W3',
  //     'Hiragino Mincho ProN', 'メイリオ', Meiryo, serif;
  //   font-size: 14px;
  //   line-height: 1.8;
  //   color: #000000;
  //   background-color: #fff;
  //   letter-spacing: 0.05em;
  // }

  // body a {
  //   color: #000000;
  // }

  // body {
  //   min-width: 1024px;
  // }

  // @media only screen and (min-width: 1260px) {
  //   body {
  //     min-width: 1260px;
  //   }
  // }

  html {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    line-height: 1;
  }
  div {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  span {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  applet {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  object {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  iframe {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  h1 {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  h2 {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  h3 {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  h4 {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  h5 {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  h6 {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  p {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  blockquote {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    quotes: none;
    &:before {
      content: '';
      content: none;
    }
    &:after {
      content: '';
      content: none;
    }
  }
  pre {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  a {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    color: #000;
    text-decoration: none;
    -webkit-touch-callout: none;
    display: block;
    &:hover {
      text-decoration: none;
    }
  }
  abbr {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  acronym {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  address {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  big {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  cite {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  code {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  del {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  dfn {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  em {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  img {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  ins {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  kbd {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  q {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    quotes: none;
    &:before {
      content: '';
      content: none;
    }
    &:after {
      content: '';
      content: none;
    }
  }
  s {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  samp {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  small {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  strike {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  strong {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  sub {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  sup {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  tt {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  var {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  b {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  u {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  i {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  center {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  dl {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  dt {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  dd {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  ol {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    list-style: none;
  }
  ul {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    list-style: none;
  }
  li {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  form {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  label {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  legend {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  table {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    border-collapse: collapse;
    border-spacing: 0;
  }
  caption {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  tbody {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  tfoot {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  thead {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  tr {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  th {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  td {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  article {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    display: block;
  }
  aside {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    display: block;
  }
  canvas {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  details {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    display: block;
  }
  embed {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  figure {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    display: block;
  }
  figcaption {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    display: block;
  }
  footer {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    display: block;
  }
  header {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    display: block;
  }
  hgroup {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    display: block;
  }
  main {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    display: block;
  }
  menu {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    display: block;
  }
  nav {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    display: block;
  }
  output {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  ruby {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  section {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    display: block;
  }
  summary {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  time {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  mark {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  audio {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  * {
    box-sizing: border-box;
    &:before {
      box-sizing: border-box;
    }
    &:after {
      box-sizing: border-box;
    }
  }
  input {
    color: #000;
    text-decoration: none;
    -webkit-touch-callout: none;
    &:hover {
      text-decoration: none;
    }
  }
  button {
    color: #000;
    text-decoration: none;
    -webkit-touch-callout: none;
    &:hover {
      text-decoration: none;
    }
  }
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    &::-ms-expand {
      display: none;
    }
    &::-ms-value {
      background: none;
      color: currentColor;
    }
  }
  .align_center {
    text-align: center;
  }
  .align_right {
    text-align: right;
  }
  .align_left {
    text-align: left;
  }
  .align_justify {
    text-align: justify;
  }
  .centered {
    margin-right: auto;
    margin-left: auto;
  }
  .bold {
    font-weight: bold;
  }
  .border {
    border-width: 1px;
    border-style: solid;
  }
  a.hover_underline {
    &:hover {
      text-decoration: underline;
    }
  }
  .hover_underline--inner {
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .flex_1 {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .svg-icon {
    display: inline-block;
    fill: currentColor;
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    overflow: hidden;
    margin-right: 7px;
    svg {
      vertical-align: top;
    }
  }
  .svg-icon--tall {
    height: 2em;
    vertical-align: middle;
  }
  .svg-icon--small-tall {
    width: 0.5em;
    vertical-align: middle;
  }
  .svg-icon--tall2 {
    height: 3em;
  }
  .svg-icon--short {
    width: 2em;
  }
  .svg-icon--small-short {
    height: 0.5em;
    vertical-align: middle;
  }
  .svg-icon--top {
    vertical-align: top;
  }
  .svg-icon--align-middle {
    vertical-align: middle;
    margin-top: -0.5em;
  }
  .svg-icon--nomargin {
    margin: 0;
  }
  .svg-icon--middle {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -0.5em;
  }
  .svg-icon--center {
    position: absolute;
    left: 50%;
    top: 0;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  .svg-icon--abs_top {
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 0.15em;
  }
  .overflow--visible {
    overflow: visible !important;
  }
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    .slick-track {
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    .slick-list {
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    &:focus {
      outline: none;
    }
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    &:before {
      display: table;
      content: '';
    }
    &:after {
      display: table;
      content: '';
      clear: both;
    }
  }
  .slick-loading {
    .slick-track {
      visibility: hidden;
    }
    .slick-slide {
      visibility: hidden;
    }
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    img {
      display: block;
    }
  }
  [dir='rtl'] {
    .slick-slide {
      float: right;
    }
  }
  .slick-slide.slick-loading {
    img {
      display: none;
    }
  }
  .slick-slide.dragging {
    img {
      pointer-events: none;
    }
  }
  .slick-initialized {
    .slick-slide {
      display: block;
    }
  }
  .slick-vertical {
    .slick-slide {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  .jln-stars {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    .svg-icon {
      font-size: 25px;
      color: #ffc333;
    }
    span {
      font-weight: bold;
      font-size: 20px;
      width: 60px;
    }
  }
  .jln-stars.pattern_2 {
    .svg-icon {
      font-size: 17px;
    }
    span {
      font-size: 12px;
      padding-right: 8px;
      width: 38px;
    }
  }
  .jln-stars.pattern_3 {
    .svg-icon {
      font-size: 17px;
    }
    span {
      font-size: 12px;
      padding-right: 8px;
      width: 38px;
      font-size: 14px;
      width: 26px;
    }
  }
  a.type_text.pattern_31 {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  .crn-prt_a4_1.pattern_32 {
    a.crn-prttl.type_text {
      text-decoration: underline;
    }
  }
  a.type_text.pattern_32 {
    text-decoration: underline;
  }

  @media only screen and (min-width: 960px) {
    body {
      /* font-family: '游明朝', YuMincho, 'ヒラギノ明朝 ProN W3',
        'Hiragino Mincho ProN', 'メイリオ', Meiryo, serif; */
      font-size: 14px;
      line-height: 1.8;
      color: #000000;
      background-color: #fff;
      letter-spacing: 0.05em;
      min-width: 1024px;
      a {
        color: #000000;
      }
    }
    .sub-content {
      width: 100%;
      > .wrap {
        position: relative;
      }
      > .wrap.sub-contents {
        // width: 1000px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .font_10 {
      font-size: 10px;
    }
    .font_12 {
      font-size: 12px;
    }
    .font_13 {
      font-size: 13px;
    }
    .font_14 {
      font-size: 14px;
    }
    .font_16 {
      font-size: 16px;
    }
    .font_18 {
      font-size: 18px;
    }
    .font_20 {
      font-size: 20px;
    }
    .font_22 {
      font-size: 22px;
    }
    .font_24 {
      font-size: 24px;
    }
    .font_26 {
      font-size: 26px;
    }
    .font_28 {
      font-size: 28px;
    }
    .lh1 {
      line-height: 1.6;
    }
    .mb_0 {
      margin-bottom: 0px !important;
    }
    .mt_0 {
      margin-top: 0px !important;
    }
    .mb_10 {
      margin-bottom: 10px !important;
    }
    .mt_10 {
      margin-top: 10px !important;
    }
    .mb_16 {
      margin-bottom: 16px !important;
    }
    .mt_16 {
      margin-top: 16px !important;
    }
    .mb_20 {
      margin-bottom: 20px !important;
    }
    .mt_20 {
      margin-top: 20px !important;
    }
    .mb_24 {
      margin-bottom: 24px !important;
    }
    .mt_24 {
      margin-top: 24px !important;
    }
    .mb_32 {
      margin-bottom: 32px !important;
    }
    .mt_32 {
      margin-top: 32px !important;
    }
    .mb_40 {
      margin-bottom: 40px !important;
    }
    .mt_40 {
      margin-top: 40px !important;
    }
    .mb_64 {
      margin-bottom: 64px !important;
    }
    .mt_64 {
      margin-top: 64px !important;
    }
    .bg--06_b {
      // background-image: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/bg/06_b-84ea341dc17fb929e174594c0dd1deeb38c18c2f6a6c26809d3fe5a26c62fbd8.png');
      background-repeat: repeat;
    }
    .bg--06_a {
      // background-image: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/bg/06_a_3-1726dae4dc1bd9ebe9b7d4f039364801472971727eeb83557d75fce584196ab0.png');
      background-repeat: repeat;
    }
    .bg--06_title {
      // background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/bg/06_title_1-97ed885a82d9a670c8bb58fae52b5518e0f819bf4ad2cf45875c86c28e301b39.svg')
      //   repeat-X bottom center;
      padding: 0 10px 20px 10px;
      display: inline-block;
    }
    .c__t5 {
      color: #ff6600;
      a {
        color: #ff6600;
      }
    }
    .c__t6 {
      color: #e73656;
      a {
        color: #e73656;
      }
    }
    .b__b21 {
      border-color: #cccccc;
    }
    .c__t7 {
      color: #007ebe;
      a {
        color: #007ebe;
      }
    }
    .c__t8 {
      color: #ffffff;
      a {
        color: #ffffff;
      }
    }
    a.l__l1 {
      color: #2e518b;
    }
    a.lh__l1 {
      &:hover {
        color: #2e518b;
      }
    }
    .l__l1--inner {
      a {
        color: #2e518b;
      }
    }
    .lh__l1--inner {
      a {
        &:hover {
          color: #2e518b;
        }
      }
    }
    .bg__bg11 {
      background-color: rgba(255, 255, 255, 0.9);
    }
    .c__t9 {
      color: var(--color-secondary);
      a {
        color: var(--color-secondary);
      }
    }
    a.l__l2 {
      color: #ffffff;
    }
    a.lh__l2 {
      &:hover {
        color: #ffffff;
      }
    }
    .l__l2--inner {
      a {
        color: #ffffff;
      }
    }
    .lh__l2--inner {
      a {
        &:hover {
          color: #ffffff;
        }
      }
    }
    .bg__bg12 {
      background-color: #efefef;
    }
    .f__f1 {
      fill: #ffffff;
    }
    .f__f2 {
      fill: var(--color-secondary);
    }
    .f__f3 {
      fill: #cbcbcb;
    }
    .bg__bg1 {
      // background-color: var(--color-primary);
    }
    .b__b1 {
      border-color: #000000;
    }
    .bg__bg2 {
      background-color: var(--color-secondary);
    }
    .b__b2 {
      border-color: #767676;
    }
    .bg__bg3 {
      // background-color: var(--color-primary);
    }
    .b__b3 {
      border-color: #efefef;
    }
    .bg__bg4 {
      background-color: rgba(46, 92, 79, 0.07);
    }
    .bg__bg5 {
      background-color: #ffffff;
    }
    .bg__bg6 {
      background-color: rgba(0, 0, 0, 0.5);
    }
    .c__t31 {
      color: #333333;
      a {
        color: #333333;
      }
    }
    .bg__bg7 {
      background-color: rgba(0, 0, 0, 0.5);
    }
    .c__t10 {
      color: #767676;
      a {
        color: #767676;
      }
    }
    .c__t21 {
      color: #ffffff;
      a {
        color: #ffffff;
      }
    }
    .bg__bg8 {
      background-color: #426c60;
    }
    .c__t11 {
      color: var(--color-secondary);
      a {
        color: var(--color-secondary);
      }
    }
    .c__t22 {
      color: var(--color-title-text);
      a {
        color: var(--color-title-text);
      }
    }
    .c__t1 {
      color: #000000;
      a {
        color: #000000;
      }
    }
    .c__t2 {
      color: #4e4e4e;
      a {
        color: #4e4e4e;
      }
    }
    .c__t3 {
      color: #767676;
      a {
        color: #767676;
      }
    }
    .c__t4 {
      color: #2e518b;
      a {
        color: #2e518b;
      }
    }
    section {
      width: 100%;
      .section-contents {
        // width: 1000px;
        margin-left: auto;
        margin-right: auto;
      }
      > .wrap {
        overflow: hidden;
        padding: 0;
        .crn-cmp {
          margin-bottom: 64px;
          &:first-child {
            margin-top: 32px;
          }
          &:last-child {
            margin-bottom: 32px;
          }
        }
      }
      &:first-child {
        > .wrap {
          .crn-cmp {
            &:first-child {
              margin-top: 64px;
            }
          }
        }
      }
      &:last-child {
        > .wrap {
          .crn-cmp {
            &:last-child {
              margin-bottom: 64px;
            }
          }
        }
      }
    }
    section.has-overflow-contents {
      > .wrap {
        overflow: visible !important;
        &:after {
          content: '';
          display: table;
        }
      }
    }
    section.type_5 {
      > .wrap {
        .crn-cmp {
          &:first-child {
            margin-top: 64px;
          }
          &:last-child {
            margin-bottom: 64px;
          }
        }
      }
      &:nth-child(odd) {
        // background-image: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/bg/06_b-84ea341dc17fb929e174594c0dd1deeb38c18c2f6a6c26809d3fe5a26c62fbd8.png');
        background-repeat: repeat;
      }
      &:nth-child(even) {
        // background-image: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/bg/06_c-9a3438a3a992c754e1383614006c3c6b8aa04f4ca468da8f1e019f6831856d3b.png');
        background-repeat: repeat;
        position: relative;
        &:after {
          display: block;
          content: '';
          width: 100%;
          max-width: 1200px;
          height: 100%;
          /* background: -webkit-linear-gradient(
            left,
            rgba(246, 242, 235, 0) 0%,
            #f6f2eb 25%,
            #f6f2eb 75%,
            rgba(246, 242, 235, 0) 100%
          );
          background: linear-gradient(
            to right,
            rgba(246, 242, 235, 0) 0%,
            #f6f2eb 25%,
            #f6f2eb 75%,
            rgba(246, 242, 235, 0) 100%
          ); */
          position: absolute;
          right: 0;
          left: 0;
          top: 0;
          margin: auto;
          z-index: 1;
        }
        > div {
          position: relative;
          z-index: 2;
        }
      }
    }
    section.type_1 {
      &:last-child {
        > .wrap {
          .crn-cmp {
            &:last-child {
              margin-bottom: 80px;
            }
          }
        }
      }
    }
    section.type_3 {
      > .wrap {
        border-right: 1px solid #000;
        border-left: 1px solid #000;
      }
    }
    section.type_4 {
      &:not(:last-of-type) {
        > .wrap {
          border-bottom: 1px solid #000;
        }
      }
    }
    section.type_7 {
      overflow: hidden;
    }
    section.type_8 {
      overflow: hidden;
      > .wrap {
        border: 1px solid #000;
      }
    }
    footer {
      width: 100%;
      overflow: hidden;
      > .wrap {
        // width: 1000px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-top: 42px;
        margin-bottom: 24px;
      }
    }
    .header {
      position: relative;
      .header-inside {
        position: absolute;
        bottom: 80px;
        left: 0;
        width: 100%;
        z-index: 401;
        pointer-events: none;
        .crn-prt {
          pointer-events: auto;
        }
        .header-part {
          > .wrap {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;
          }
        }
      }
      .header-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 99;
      }
      .header-main {
        position: relative;
      }
      .header-part {
        .header-contents {
          // width: 1000px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    .header.pattern_2 {
      .header-top {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        pointer-events: none;
        > .header-part {
          pointer-events: auto;
        }
      }
    }
    .header.pattern_4 {
      * {
        min-height: 0;
        min-width: 0;
      }
      .mv-contents {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
      .mv-container {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        .crn-prt {
          width: 100%;
        }
      }
      .mv-inside {
        width: 322px;
        position: relative;
      }
      .mv-inside__wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 20px;
        z-index: 500;
      }
    }
    .header.pattern_4.mv-reverse {
      .mv-contents {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
      }
    }
    .header.pattern_3 {
      .header-inside {
        .header-part {
          > .wrap {
            padding: 0 10px;
          }
        }
      }
      .header-bottom {
        > .crn-prt {
          // width: 1000px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    .header.pattern_11 {
      .header-part {
        > .wrap {
          width: 100% !important;
        }
      }
    }
    .flex {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .flex.align-start {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .flex.align-end {
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;
    }
    .flex.align-center {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .flex.align-stretch {
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }
    .flex.justify-sb {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    .flex.justify-c {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .flex.justify-end {
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }
    .flex.flow-column {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column;
      flex-flow: column;
    }
    .flex.wrap {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .decor {
      position: relative;
      &:after {
        position: absolute;
        display: block;
        content: '';
      }
    }
    .decor.decor--tl {
      &:after {
        top: 0;
        left: 0;
      }
    }
    .decor.decor--tc {
      &:after {
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
      }
    }
    .decor.decor--tr {
      &:after {
        top: 0;
        right: 0;
      }
    }
    .decor.decor--bl {
      &:after {
        bottom: 0;
        left: 0;
      }
    }
    .decor.decor--bc {
      &:after {
        bottom: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
      }
    }
    .decor.decor--br {
      &:after {
        bottom: 0;
        right: 0;
      }
    }
    .decor.decor--ml {
      &:after {
        top: 50%;
        left: 0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
      }
    }
    .decor.decor--mr {
      &:after {
        top: 50%;
        right: 0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
      }
    }
    .icon {
      position: relative;
      &:after {
        position: absolute;
        display: block;
        content: '';
        bottom: 0;
        left: 0;
      }
    }
    .icon.icon--google {
      width: 122px;
      height: 16px;
      background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/google_white-82c2b7ad57de43de1d37cddf80bb74d8518a40b5609636839693f465c6570e5b.png')
        transparent no-repeat;
      background-size: cover;
    }
    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .clearfix {
      &:after {
        content: '';
        display: block;
        visibility: hidden;
        height: 0;
        clear: both;
      }
    }
    .vertical {
      -ms-writing-mode: tb-rl;
      -webkit-writing-mode: vertical-rl;
      writing-mode: vertical-rl;
    }
    .crn-prt.type_block {
      position: relative;
    }
    .crn-prt.type_block.pattern_2 {
      > .crn-prt {
        display: inline-block;
      }
    }
    .crn-prt.type_block.pattern_21 {
      position: absolute;
      top: 0;
      left: 0;
    }
    .crn-prt.type_bread_crumb {
      li {
        display: inline;
        a {
          display: inline-block;
        }
      }
      li + li {
        &:before {
          content: '＞';
          margin: 0 7px;
        }
      }
    }
    .crn-prt.type_bread_crumb.pattern_31 {
      li {
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .crn-prt.type_bread_crumb.pattern_32 {
      li {
        a {
          text-decoration: underline;
        }
      }
    }
    .crn-prt.type_button {
      display: inline-block;
      text-align: center;
      cursor: pointer;
      font-weight: bold;
    }
    .crn-prt.type_button.pattern_1 {
      .btn--body {
        border: none;
      }
    }
    .crn-prt.type_button.pattern_2 {
      .btn--body {
        border-style: solid;
      }
    }
    .crn-prt.type_button.pattern_21 {
      .btn--body {
        border-style: solid;
      }
    }
    .crn-prt.type_button.align_right {
      float: right;
      &:after {
        content: '';
        display: table;
        clear: both;
      }
    }
    .crn-prt.type_nav {
      a {
        position: relative;
      }
      span {
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-all;
      }
    }
    .crn-prt.type_nav.pattern_1 {
      li {
        display: inline-block;
        vertical-align: top;
      }
    }
    .crn-prt.type_nav.pattern_2 {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }
      li {
        position: relative;
        text-align: center;
      }
      li.current {
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;
        }
      }
      a {
        display: table;
        table-layout: fixed;
        height: 58px;
        span {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
    .crn-prt.type_nav.pattern_3 {
      li {
        display: block;
      }
    }
    .crn-prt.type_nav.pattern_4 {
      li {
        a {
          display: inline-block;
          padding-bottom: 12px;
          position: relative;
        }
      }
      li.current {
        a {
          &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            width: 100%;
          }
        }
      }
    }
    .crn-prt.type_nav.pattern_21 {
      li {
        a {
          padding: 14px 38px 16px 14px;
        }
      }
    }
    .crn-prt.type_list.pattern_2 {
      > ul {
        > li {
          display: inline-block;
          vertical-align: top;
        }
      }
    }
    .crn-prt.type_list.pattern_3 {
      > ul {
        > li {
          display: inline-block;
          vertical-align: top;
        }
      }
    }
    .crn-prt.type_list.pattern_11 {
      ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        li {
          min-width: 0;
          .crn-prt {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .crn-prt.type_list.pattern_12 {
      ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        li {
          min-width: 0;
          .crn-prt {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .crn-prt.type_paging {
      a {
        display: inline-block;
      }
      .paging__wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        line-height: 16px;
      }
      .paging__icon {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          width: 9px;
          height: 16px;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/paging-987dd577527bd1cab4e8b530566f4c2fde8701aba85d3d90f984f3a3fa200b61.png')
            transparent repeat;
        }
      }
      .paging__first {
        margin-right: 20px;
        padding-left: 26px;
        &:before {
          left: 0;
          width: 18px;
          -webkit-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
      .paging__prev {
        padding-left: 17px;
        &:before {
          left: 0;
          -webkit-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
      .paging__last {
        margin-left: 20px;
        padding-right: 26px;
        &:before {
          right: 0;
          width: 18px;
        }
      }
      .paging__next {
        padding-right: 17px;
        position: relative;
        &:before {
          right: 0;
        }
      }
    }
    .crn-prt.type_paging.pattern_1 {
      .paging__nav-prev {
        width: 270px;
        text-align: left;
      }
      .paging__pager {
        width: 100px;
        text-align: center;
      }
      .paging__nav-next {
        width: 270px;
        text-align: right;
      }
    }
    .crn-prt.type_paging.pattern_31 {
      .paging__nav-prev {
        width: 270px;
        text-align: left;
      }
      .paging__pager {
        width: 100px;
        text-align: center;
      }
      .paging__nav-next {
        width: 270px;
        text-align: right;
      }
      .paging__icon {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/paging_white-1219d6061bfd24a13ca2d39425e161636836ef297a0daad2cdffae442e8594cc.png')
            transparent repeat;
        }
      }
    }
    .crn-prt.type_paging.pattern_21 {
      .paging__wrapper {
        padding: 10px;
        position: relative;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
      }
      .paging__archive_wrapper {
        display: block;
        border-bottom: 1px solid #ccc;
        text-align: center;
      }
      .paging__archive {
        display: inline-block;
        padding: 10px;
        line-height: 16px;
      }
      .paging__pager {
        display: block;
        padding: 10px;
        text-align: center;
        line-height: 16px;
      }
      .paging__spr {
        height: 16px;
        width: 1px;
        background: #ccc;
      }
      .paging__nav-prev {
        margin-right: 20px;
      }
      .paging__nav-next {
        margin-left: 20px;
      }
      .paging__first {
        position: absolute;
        left: 10px;
      }
      .paging__last {
        position: absolute;
        right: 10px;
      }
    }
    .crn-prt.type_paging.pattern_32 {
      .paging__wrapper {
        padding: 10px;
        position: relative;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
      }
      .paging__archive_wrapper {
        display: block;
        border-bottom: 1px solid #ccc;
        text-align: center;
      }
      .paging__archive {
        display: inline-block;
        padding: 10px;
        line-height: 16px;
      }
      .paging__pager {
        display: block;
        padding: 10px;
        text-align: center;
        line-height: 16px;
      }
      .paging__spr {
        height: 16px;
        width: 1px;
        background: #ccc;
      }
      .paging__nav-prev {
        margin-right: 20px;
      }
      .paging__nav-next {
        margin-left: 20px;
      }
      .paging__first {
        position: absolute;
        left: 10px;
      }
      .paging__last {
        position: absolute;
        right: 10px;
      }
      .paging__icon {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/paging_white-1219d6061bfd24a13ca2d39425e161636836ef297a0daad2cdffae442e8594cc.png')
            transparent repeat;
        }
      }
    }
    .crn-prt.type_separator.editable {
      padding-bottom: 20px;
    }
    .crn-prt.type_table {
      width: 100%;
      th {
        font-weight: bold;
        text-align: left;
      }
    }
    .crn-prt.type_table.pattern_2 {
      th {
        text-align: right;
      }
    }
    .crn-prt.type_text {
      position: relative;
      .text--more {
        display: inline;
        cursor: pointer;
      }
      .text--more.is-pc {
        &:hover {
          text-decoration: underline;
        }
      }
      .text--toggle-pattern2.text--more {
        display: block;
        padding: 5px;
        border-style: solid;
        border-width: 1px;
        border-color: #dedede;
        text-align: center;
        position: relative;
        background-color: #fff;
        &:before {
          content: '';
          display: block;
          z-index: 1;
          position: absolute;
          width: 12px;
          height: 6px;
          right: 15px;
          top: 10px;
          pointer-events: none;
          background-size: cover;
        }
      }
      .text--toggle-pattern2.text--more.text--toggle-close {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_down-415dd1dd6d66785e8d87d38211d49e9e00fe96b43e706488976777cd33c9ba24.svg')
            transparent no-repeat;
        }
      }
      .text--toggle-pattern2.text--more.text--toggle-open {
        margin-top: 12px;
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_up-c2b08f78dbf45a22e1fc002bcc87f78114be35078bcb33929285b9e19a1365ce.svg')
            transparent no-repeat;
        }
      }
      .text--more-pattern1.text--more {
        text-decoration: underline;
      }
      .text--more-pattern2.text--more {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .crn-prt.type_text.pattern_11 {
      border-right: 1px solid;
    }
    .crn-prt.type_text.pattern_32 {
      .text--more {
        border-color: #555555;
        background-color: #272727;
      }
      .text--more.text--toggle-close {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_down_white-ddba43bda25f6b47dc5ede1567efabe1481d18d00ecf24ff95184529d345acc4.svg')
            transparent no-repeat;
        }
      }
      .text--more.text--toggle-open {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_up_white-aeff02e433d16c3f283a6974fac56ec1c5ad7870a0ce69d5f1fa5ce9c57cf73f.svg')
            transparent no-repeat;
        }
      }
    }
    .crn-prt.type_image {
      max-width: 100%;
      overflow: hidden;
      position: relative;
      img {
        vertical-align: top;
      }
      .image-caption {
        position: absolute;
        width: 100%;
        text-align: center;
        padding: 10px;
      }
      .image-caption.pattern_1 {
        top: 0;
      }
      .image-caption.pattern_2 {
        bottom: 0;
        line-height: 1.5;
        letter-spacing: 0;
      }
    }
    .crn-prt.type_image.pattern_2 {
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
    .crn-prt.type_image.pattern_3 {
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
      }
    }
    .crn-prt.type_image.pattern_6 {
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
    .crn-prt.type_image.pattern_4 {
      img {
        min-width: 100%;
        min-height: 100%;
      }
    }
    .crn-prt.type_image.pattern_5 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .crn-prt.type_image.pattern_21 {
      img {
        width: 100%;
        height: auto;
      }
    }
    .crn-prt.type_freepage {
      .fp-frame {
        margin-bottom: 32px;
      }
      .fp-frame.fp-dummy {
        text-align: center;
        font-size: 24px;
        padding: 20px;
      }
    }
    .crn-prt.type_columns {
      ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
      }
      li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        .column-wrap {
          -webkit-box-flex: 1;
          -ms-flex: 1 0 auto;
          flex: 1 0 auto;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
        }
        .inner {
          -webkit-box-flex: 1;
          -ms-flex: 1 0 auto;
          flex: 1 0 auto;
        }
        .column-contents {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-flow: column;
          flex-flow: column;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
        }
      }
    }
    .crn-prt.type_columns.pattern_1 {
      .outer {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }
      .column-contents {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
      }
    }
    .crn-prt.type_columns.pattern_2 {
      .inner {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }
      .column-contents {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
      }
    }
    .crn-prt.type_columns.pattern_3 {
      .inner {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
      }
    }
    .crn-prt.type_columns.pattern_4 {
      .title {
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 1.5;
        letter-spacing: 0;
        font-weight: bold;
        a.pattern_31 {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .desc {
        margin-top: 10px;
      }
      .button {
        margin-top: 10px;
        font-size: 12px;
        line-height: 18px;
      }
      .pattern_31 {
        .title {
          a {
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
    .crn-prt.type_columns.i_circle {
      .part.type_image {
        border-radius: 50%;
        margin: 20px auto;
      }
    }
    .crn-prt_t5 {
      li {
        .inner {
          padding: 18px;
          background-color: #ffffff;
        }
        padding: 4px;
        width: 25%;
        .crn-prt {
          &:not(:last-child) {
            margin-bottom: 18px;
          }
        }
        a.column-wrap {
          &:hover {
            opacity: 0.6;
          }
        }
      }
      ul {
        margin: -4px;
      }
      .crn-prt.type_image {
        height: 183px;
      }
    }
    .crn-prt.type_jln_search {
      .calendar--false {
        display: none;
      }
      .calendar--true {
        display: block;
      }
      .calendar-staylength {
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding: 0 7px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        .stayDates--undecided {
          color: #999999;
          display: block;
        }
        .stayDates--arrow {
          margin: 0 5px;
        }
        .stayDates--length {
          margin-left: 3px;
        }
      }
      .calendar-staylength.is-pc {
        letter-spacing: 0;
      }
      .calendar--outsideClickHandler {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 450;
      }
      .search-wrap {
        position: relative;
      }
      .search-label {
        font-size: 12px;
        padding-left: 3px;
      }
      .search-area-label {
        font-weight: bold;
        font-size: 16px;
      }
      .search-area-label.is-sp {
        font-size: 14px;
        margin-bottom: 10px;
      }
      .staylength-wrap {
        position: relative;
        background-color: #fff;
        color: #000000;
        border: 1px solid #333;
        width: 100%;
      }
      .select-wrap {
        position: relative;
        background-color: #fff;
        color: #000000;
        border: 1px solid #333;
        width: 100%;
        select {
          cursor: pointer;
          font-size: 14px;
          background-color: transparent;
          border-style: none;
          color: inherit;
          width: 100%;
          padding: 7px;
          height: 40px;
        }
        .number--inEditor {
          cursor: default;
          font-size: 14px;
          background-color: transparent;
          border-style: none;
          color: inherit;
          width: 100%;
          padding: 7px;
          height: 40px;
          line-height: 28px;
          display: block;
        }
        &:before {
          content: '';
          display: block;
          z-index: 1;
          position: absolute;
          width: 16px;
          height: 9px;
          right: 15px;
          top: 15px;
          pointer-events: none;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_down-5bdd393dafe6dda3899a01248184114dafb414a35b44223627d77eb99f22518a.png')
            transparent no-repeat;
          background-size: cover;
        }
      }
      .search-calendar.is-pc {
        line-height: 1.5;
        letter-spacing: 0;
      }
      .search-number.is-pc {
        line-height: 1.5;
        letter-spacing: 0;
      }
      .search-calendar.is-sp {
        .staylength-wrap {
          color: #333333;
        }
      }
      .search-number.is-sp {
        .select-wrap {
          color: #333333;
        }
      }
      .calendar-open {
        display: block;
        position: absolute;
        z-index: 500;
        background-color: #fff;
        border: 1px solid #cccccc;
        padding: 30px 20px 15px;
        width: 690px;
        box-sizing: border-box;
        overflow: hidden;
        top: 59px;
        .tbd {
          text-align: right;
          margin-top: 6px;
          padding-right: 10px;
          font-size: 14px;
          a {
            display: inline-block;
          }
          a.tbd__close {
            display: none;
          }
        }
      }
      .calendar-open.is-pc {
        letter-spacing: 0;
        .tbd {
          a {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .calendar-open.is-sp {
        width: 100%;
        left: 0;
        top: 60px;
        border-color: #333333;
        padding: 0;
        .DayPicker__week-header {
          font-weight: bold;
        }
        .CalendarDay.CalendarDay--selected-start {
          button {
            font-weight: bold;
          }
        }
        .CalendarDay.CalendarDay--selected-end {
          button {
            font-weight: bold;
          }
        }
        .CalendarDay.CalendarDay--selected-span {
          button {
            font-weight: bold;
          }
        }
        .calendar-open__inner {
          width: 272px;
          margin: 0 auto;
          overflow: hidden;
          padding: 18px 0 0;
        }
        .tbd {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          padding: 12px;
          margin-top: 0;
          border-top: 1px solid #efefef;
          a {
            font-size: 12px;
            display: block;
          }
          a.tbd__close {
            color: #333333;
            display: block;
            position: relative;
            padding-left: 14px;
            span {
              position: absolute;
              display: block;
              top: 50%;
              left: 0;
              margin-top: -6px;
              width: 1px;
              height: 12px;
              background: #333;
              &:nth-child(1) {
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
              }
              &:nth-child(2) {
                -webkit-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
              }
            }
          }
        }
        .DayPickerNavigation--horizontal {
          .DayPickerNavigation__prev {
            left: 0px;
          }
          .DayPickerNavigation__next {
            right: 44px;
          }
        }
      }
      .CalendarDay {
        padding: 0;
        box-sizing: border-box;
        cursor: pointer;
        height: 40px !important;
        color: #333333;
        &:nth-child(7n + 6) {
          color: #03c;
        }
        &:nth-child(7n) {
          color: #c03;
        }
        .CalendarDay--outside {
          border: 0;
          cursor: default;
          &:active {
            background: #fff;
          }
        }
      }
      .CalendarDay.CalendarDay--hovered {
        color: #fff !important;
        opacity: 1 !important;
      }
      .CalendarDay.CalendarDay--selected-span {
        color: #fff !important;
      }
      .CalendarDay.CalendarDay--selected-span.CalendarDay--hovered {
        color: #fff !important;
      }
      .CalendarDay.CalendarDay--hovered-span {
        color: #fff !important;
      }
      .CalendarDay.CalendarDay--selected-start {
        color: #fff !important;
        font-weight: bold;
        opacity: 1;
      }
      .CalendarDay.CalendarDay--selected-end {
        color: #fff !important;
        font-weight: bold;
        opacity: 1;
      }
      .CalendarDay.CalendarDay--selected {
        color: #fff !important;
        font-weight: bold;
        opacity: 1;
      }
      .CalendarDay.CalendarDay--blocked-out-of-range {
        color: #ccc !important;
        background: #fff;
        cursor: default;
        .CalendarDay__button {
          cursor: default;
        }
        &:active {
          background: #fff;
        }
      }
      .CalendarDay__button {
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        position: relative;
        height: 100%;
        width: 100%;
        text-align: center;
        background: none;
        border: 0;
        margin: 0;
        padding: 0;
        font-size: inherit;
        color: inherit;
        line-height: normal;
        cursor: pointer;
        box-sizing: border-box;
        outline: none;
        &:active {
          outline: 0;
        }
      }
      .CalendarMonth {
        text-align: center;
        vertical-align: top;
        padding-right: 27px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        table {
          border-collapse: collapse;
          border-spacing: 0;
          caption-caption-side: initial;
        }
      }
      .CalendarMonth--horizontal {
        &:first-of-type {
          position: absolute;
          z-index: -1;
          opacity: 0;
          pointer-events: none;
        }
        display: inline-block;
        min-height: 100%;
        .CalendarMonth__caption {
          padding: 0 0 35px;
        }
      }
      .CalendarMonth__caption {
        color: #555;
        margin-top: 7px;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
      }
      .CalendarMonthGrid {
        background: #fff;
        z-index: 0;
        text-align: left;
      }
      .CalendarMonthGrid--animating {
        -webkit-transition-duration: 0.001s;
        transition-duration: 0.001s;
        z-index: 1;
      }
      .transition-container {
        position: relative;
        overflow: hidden;
      }
      .CalendarMonthGrid--horizontal {
        position: absolute;
      }
      .CalendarDay.CalendarDay--highlighted-calendar {
        color: #c03;
      }
      .DayPicker {
        background: #fff;
        position: relative;
        text-align: left;
        left: 2px;
      }
      .DayPicker--horizontal {
        background: #fff;
      }
      .DayPicker__focus-region {
        outline: none;
      }
      .DayPicker__week-headers {
        position: relative;
      }
      .DayPicker__week-header {
        position: absolute;
        top: 42px;
        z-index: 2;
        text-align: left;
        ul {
          display: table;
          list-style: none;
        }
        li {
          display: table-cell;
          text-align: center;
          font-size: 12px;
          color: #333333;
          &:nth-child(7n + 6) {
            color: #03c;
          }
          &:nth-child(7n) {
            color: #c03;
          }
        }
      }
      .calstart-false {
        .DayPickerNavigation__prev {
          display: none;
        }
      }
      .calend-false {
        .DayPickerNavigation__next {
          display: none;
        }
      }
      .DayPickerNavigation__prev {
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        background: #fff;
        cursor: pointer;
        border: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        outline: none;
        z-index: 2;
        position: absolute;
        width: 30px;
        height: 30px;
        top: 2px;
        svg {
          display: none;
        }
      }
      .DayPickerNavigation__next {
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        background: #fff;
        cursor: pointer;
        border: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        outline: none;
        z-index: 2;
        position: absolute;
        width: 30px;
        height: 30px;
        top: 2px;
        svg {
          display: none;
        }
      }
      .DayPickerNavigation--horizontal {
        position: relative;
        .DayPickerNavigation__prev {
          left: 11px;
          &:before {
            content: '';
            width: 8px;
            height: 8px;
            border: 0px;
            border-top: solid 1px #222;
            border-right: solid 1px #222;
            -webkit-transform: rotate(225deg);
            -ms-transform: rotate(225deg);
            transform: rotate(225deg);
            position: absolute;
            top: 50%;
            left: 10px;
            margin-top: -4px;
          }
        }
        .DayPickerNavigation__next {
          right: 55px;
          &:before {
            content: '';
            width: 8px;
            height: 8px;
            border: 0px;
            border-top: solid 1px #222;
            border-right: solid 1px #222;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: 50%;
            right: 10px;
            margin-top: -4px;
          }
        }
      }
    }
    .crn-prt.type_jln_search.pattern_1 {
      padding: 40px 0 46px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;
      .search-wrap {
        width: 136px;
      }
      .search-wrap.search-calendar {
        width: 336px;
      }
      .calendar-open {
        left: 0;
      }
      .search-number {
        margin-right: 10px;
        .select-wrap {
          border-left: none;
        }
      }
    }
    .crn-prt.type_jln_search.pattern_2 {
      .search-wrap {
        margin-bottom: 0px;
      }
    }
    .crn-prt_hd18b {
      width: 322px;
      padding: 0px 22px 12px;
      .CalendarDay.CalendarDay--hovered-span {
        background: #d7caac;
      }
      .CalendarDay.CalendarDay--selected-span {
        background: #d7caac;
      }
      .CalendarDay.CalendarDay--blocked-calendar {
        background: #d7caac;
      }
      .calendar-staylength.is-pc.is-panel--true {
        border-bottom-color: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--hovered {
        background: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--selected {
        background: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--selected-start {
        background: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--selected-end {
        background: var(--color-secondary);
      }
      .tbd {
        a.tbd__none {
          color: #2e518b;
        }
      }
      .calendar-open {
        right: 0;
      }
    }
    .crn-prthl6e3 {
      a {
        display: inline-block;
        width: 100%;
      }
    }
    .crn-prt.type_single_column {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      .content {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
      }
      .image {
        .crn-prt.type_text {
          margin-top: 10px;
        }
      }
    }
    .crn-prtsc1b {
      .image {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 424px;
        flex: 0 0 424px;
        margin-right: 32px;
        width: 424px;
        max-width: 424px;
        .crn-prt.type_image {
          width: 424px;
        }
      }
    }
    .crn-prt_rvw5 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 64px;
          }
        }
      }
    }
    .crn-prtt5 {
      .text--more {
        color: #2e518b;
      }
    }
    .crn-prt.type_jln_qa {
      .jln-qa__category {
        text-align: right;
        a {
          display: inline-block;
        }
      }
      .jln-qa__question {
        padding-left: 48px;
        position: relative;
        font-size: 18px;
        line-height: 1.5;
        letter-spacing: 0;
        font-weight: bold;
        margin-bottom: 32px;
        .svg-icon {
          position: absolute;
          top: 0;
          left: 0;
        }
        h3 {
          margin-bottom: 12px;
        }
      }
      .jln-qa__answer {
        padding-left: 48px;
        position: relative;
        .svg-icon {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    .crn-prt.type_jln_qa.pattern_21 {
      .jln-qa__category {
        margin-bottom: 12px;
        font-size: 12px;
      }
      .jln-qa__question {
        padding-left: 0;
        font-size: 15px;
        margin-bottom: 14px;
        line-height: 22px;
        letter-spacing: -0.05em;
        h3 {
          padding-left: 28px;
        }
      }
      .jln-qa__answer {
        padding-left: 0;
        padding-left: 28px;
      }
    }
    .crn-prt.type_jln_qa.pattern_32 {
      .jln-qa__category {
        margin-bottom: 12px;
        font-size: 12px;
        a {
          text-decoration: underline;
        }
      }
      .jln-qa__question {
        padding-left: 0;
        font-size: 15px;
        margin-bottom: 14px;
        line-height: 22px;
        letter-spacing: -0.05em;
        h3 {
          padding-left: 28px;
        }
      }
      .jln-qa__answer {
        padding-left: 0;
        padding-left: 28px;
      }
    }
    .crn-prt.type_jln_qa.pattern_31 {
      .jln-qa__category {
        a {
          display: inline-block;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .crn-prt_r4 {
      > .crn-prt {
        &:not(:last-child) {
          margin-right: 32px;
        }
      }
    }
    .crn-prt_hd18c {
      width: 322px;
      padding: 0px 22px 12px;
      .CalendarDay.CalendarDay--hovered-span {
        background: #d7caac;
      }
      .CalendarDay.CalendarDay--selected-span {
        background: #d7caac;
      }
      .CalendarDay.CalendarDay--blocked-calendar {
        background: #d7caac;
      }
      .calendar-staylength.is-pc.is-panel--true {
        border-bottom-color: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--hovered {
        background: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--selected {
        background: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--selected-start {
        background: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--selected-end {
        background: var(--color-secondary);
      }
      .tbd {
        a.tbd__none {
          color: #2e518b;
        }
      }
      .calendar-open {
        left: 0;
      }
    }
    .crn-prtsc1 {
      .image {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 424px;
        flex: 0 0 424px;
        margin-right: 32px;
        width: 424px;
        max-width: 424px;
        .crn-prt.type_image {
          width: 424px;
        }
      }
    }
    .crn-prt.type_jln_review {
      position: relative;
      padding-top: 28px;
      .jln-review__icon {
        font-size: 48px;
        position: absolute;
        top: 0;
        left: 0;
      }
      .jln-review__date {
        position: absolute;
        top: 0;
        right: 0;
        color: #767676;
      }
      .jln-review__title {
        position: relative;
        padding-left: 56px;
        margin-bottom: 24px;
        h4 {
          font-size: 18px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: 0;
        }
      }
      .jln-review__ranks {
        float: left;
      }
      .jln-review__ranks__all {
        display: inline-block;
        color: #767676;
        margin-left: 20px;
      }
      .jln-review__desc {
        margin-bottom: 24px;
        font-size: 16px;
      }
      .jln-review__details {
        margin-bottom: 10px;
        > div {
          display: inline-block;
        }
      }
      .jln-review__details__label {
        width: 100px;
      }
      .jln-review__answer__box {
        margin: 32px 24px 0;
        border: 1px solid #ccc;
        padding: 16px;
      }
      .jln-review__answer__title {
        margin-bottom: 16px;
        line-height: 24px;
        &:after {
          content: '';
          display: block;
          visibility: hidden;
          height: 0;
          clear: both;
        }
      }
      .jln-review__answer__title__name {
        display: inline-block;
        position: relative;
        padding-left: 38px;
        font-weight: bold;
        .jln-review__icon {
          font-size: 24px;
        }
      }
      .jln-review__answer__title__at {
        float: right;
        color: #767676;
      }
    }
    .crn-prt.type_jln_review.pattern_21 {
      .jln-review__date {
        font-size: 10px;
      }
      .jln-review__icon {
        position: relative;
        float: left;
      }
      .jln-review__title {
        padding-left: 0px;
        h4 {
          font-size: 14px;
          margin-top: 12px;
          letter-spacing: -0.05em;
        }
      }
      .jln-review__ranks {
        margin-top: 9px;
        margin-left: 12px;
        .jln-stars {
          span {
            width: 20px;
            height: 30px;
            padding-top: 2.5px;
          }
          .svg-icon {
            font-size: 20px;
            height: 30px;
            svg {
              vertical-align: middle;
            }
          }
        }
      }
      .jln-review__ranks__all {
        font-size: 10px;
        clear: both;
        margin-left: 0px;
        display: block;
        padding-top: 12px;
      }
      .jln-review__details {
        font-size: 12px;
        margin-bottom: 10px;
        > div {
          display: inline;
        }
      }
      .jln-review__details__label {
        font-weight: bold;
        display: inline-block;
      }
      .jln-review__details__name {
        font-weight: bold;
        font-size: 13px;
      }
      .jln-review__desc {
        margin-bottom: 10px;
        font-size: 14px;
      }
      .jln-review__answer {
        margin-top: 20px;
        border: 1px solid #ccc;
        background-color: #fff;
      }
      .jln-review__answer__box {
        margin: 0;
        border: none;
        border-bottom: 1px solid #ccc;
        padding: 16px;
      }
      .jln-review__answer__toggle {
        text-align: center;
        padding: 8px;
        position: relative;
        &:before {
          content: '';
          display: block;
          z-index: 1;
          position: absolute;
          width: 16px;
          height: 8px;
          right: 15px;
          top: 14px;
          pointer-events: none;
          background-size: cover;
        }
      }
      .jln-review__answer__toggle.toggle-close {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_down-415dd1dd6d66785e8d87d38211d49e9e00fe96b43e706488976777cd33c9ba24.svg')
            transparent no-repeat;
        }
      }
      .jln-review__answer__toggle.toggle-open {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_up-c2b08f78dbf45a22e1fc002bcc87f78114be35078bcb33929285b9e19a1365ce.svg')
            transparent no-repeat;
        }
      }
      .jln-review__answer__title {
        display: block;
        position: relative;
        margin-bottom: 16px;
        line-height: 24px;
      }
      .jln-review__answer__title__name {
        position: relative;
        font-weight: bold;
        padding-left: 0px;
        padding-top: 28px;
        .jln-review__icon {
          display: none;
        }
      }
      .jln-review__answer__title__at {
        float: none;
        color: #767676;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 10px;
      }
    }
    .crn-prt.type_jln_review.pattern_32 {
      .jln-review__date {
        font-size: 10px;
        color: #ffffff;
      }
      .jln-review__icon {
        position: relative;
        float: left;
      }
      .jln-review__title {
        padding-left: 0px;
        h4 {
          font-size: 14px;
          margin-top: 12px;
          letter-spacing: -0.05em;
        }
      }
      .jln-review__ranks {
        margin-top: 9px;
        margin-left: 12px;
        .jln-stars {
          span {
            width: 20px;
            height: 30px;
            padding-top: 2.5px;
          }
          .svg-icon {
            font-size: 20px;
            height: 30px;
            svg {
              vertical-align: middle;
            }
          }
        }
      }
      .jln-review__ranks__all {
        font-size: 10px;
        clear: both;
        margin-left: 0px;
        display: block;
        padding-top: 12px;
        color: #ffffff;
      }
      .jln-review__details {
        font-size: 12px;
        margin-bottom: 10px;
        > div {
          display: inline;
        }
      }
      .jln-review__details__label {
        font-weight: bold;
        display: inline-block;
      }
      .jln-review__details__name {
        font-weight: bold;
        font-size: 13px;
      }
      .jln-review__desc {
        margin-bottom: 10px;
        font-size: 14px;
      }
      .jln-review__answer {
        margin-top: 20px;
        border: 1px solid #ccc;
        background-color: #fff;
        background-color: #272727;
      }
      .jln-review__answer__box {
        margin: 0;
        border: none;
        border-bottom: 1px solid #ccc;
        padding: 16px;
      }
      .jln-review__answer__toggle {
        text-align: center;
        padding: 8px;
        position: relative;
        &:before {
          content: '';
          display: block;
          z-index: 1;
          position: absolute;
          width: 16px;
          height: 8px;
          right: 15px;
          top: 14px;
          pointer-events: none;
          background-size: cover;
        }
      }
      .jln-review__answer__toggle.toggle-close {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_down-415dd1dd6d66785e8d87d38211d49e9e00fe96b43e706488976777cd33c9ba24.svg')
            transparent no-repeat;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_down_white-ddba43bda25f6b47dc5ede1567efabe1481d18d00ecf24ff95184529d345acc4.svg')
            transparent no-repeat;
        }
      }
      .jln-review__answer__toggle.toggle-open {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_up-c2b08f78dbf45a22e1fc002bcc87f78114be35078bcb33929285b9e19a1365ce.svg')
            transparent no-repeat;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_up_white-aeff02e433d16c3f283a6974fac56ec1c5ad7870a0ce69d5f1fa5ce9c57cf73f.svg')
            transparent no-repeat;
        }
      }
      .jln-review__answer__title {
        display: block;
        position: relative;
        margin-bottom: 16px;
        line-height: 24px;
      }
      .jln-review__answer__title__name {
        position: relative;
        font-weight: bold;
        padding-left: 0px;
        padding-top: 28px;
        .jln-review__icon {
          display: none;
        }
      }
      .jln-review__answer__title__at {
        float: none;
        color: #767676;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 10px;
        color: #ffffff;
      }
    }
    .crn-prt.type_jln_review.pattern_31 {
      .jln-review__date {
        color: #ffffff;
      }
      .jln-review__ranks__all {
        color: #ffffff;
      }
      .jln-review__answer {
        background-color: #272727;
      }
      .jln-review__answer__toggle.toggle-close {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_down_white-ddba43bda25f6b47dc5ede1567efabe1481d18d00ecf24ff95184529d345acc4.svg')
            transparent no-repeat;
        }
      }
      .jln-review__answer__toggle.toggle-open {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_up_white-aeff02e433d16c3f283a6974fac56ec1c5ad7870a0ce69d5f1fa5ce9c57cf73f.svg')
            transparent no-repeat;
        }
      }
      .jln-review__answer__title__at {
        color: #ffffff;
      }
    }
    .crn-prt_hd18 {
      width: 322px;
      padding: 10px 22px 12px;
      .CalendarDay.CalendarDay--hovered-span {
        background: #d7caac;
      }
      .CalendarDay.CalendarDay--selected-span {
        background: #d7caac;
      }
      .CalendarDay.CalendarDay--blocked-calendar {
        background: #d7caac;
      }
      .calendar-staylength.is-pc.is-panel--true {
        border-bottom-color: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--hovered {
        background: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--selected {
        background: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--selected-start {
        background: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--selected-end {
        background: var(--color-secondary);
      }
      .tbd {
        a.tbd__none {
          color: #2e518b;
        }
      }
      .calendar-open {
        right: 0;
      }
    }
    a.crn-prtt6 {
      display: inline-block;
    }
    .crn-prt_r5 {
      > .crn-prt {
        &:not(:last-child) {
          margin-right: 32px;
        }
      }
    }
    .crn-prtsc2 {
      .image {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 480px;
        flex: 0 0 480px;
        margin-right: 32px;
        width: 480px;
        max-width: 480px;
        .crn-prt.type_image {
          width: 480px;
        }
      }
    }
    .crn-prt.type_box {
      overflow: hidden;
    }
    .crn-prt.type_box.pattern_2 {
      border-style: solid;
    }
    .crn-prt.type_box.pattern_3 {
      border-style: solid;
    }
    .crn-prt_hd19 {
      width: 322px;
      > .content {
        padding: 6px 25px;
      }
    }
    .crn-prtt7 {
      padding: 0 4px;
    }
    .crn-prtm1 {
      width: 100%;
      height: 400px;
    }
    .crn-prtt8 {
      padding: 0 4px;
    }
    .crn-prtal1 {
      ul {
        .crn-prt {
          max-width: 970px;
        }
      }
    }
    .crn-prt_fp_table_right_bdr {
      border-width: 1px;
      border-color: #cccccc;
      > .content {
        padding: 40px 60px;
      }
    }
    .crn-prt.type_jln_language.pattern_1 {
      position: relative;
      border-left-width: 1px;
      border-left-style: solid;
      .lang-bg {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 500;
      }
      .lang-label {
        padding: 0 30px;
        width: 137px;
        cursor: pointer;
        vertical-align: bottom;
        &:hover {
          text-decoration: underline;
        }
      }
      .svg-icon {
        font-size: 14px;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -7px;
      }
      .lang-list {
        width: 100%;
        position: absolute;
        margin-top: 5px;
        background-color: #efefef;
        padding: 20px;
        z-index: 501;
        li {
          &:not(:last-child) {
            padding-bottom: 20px;
          }
        }
        a {
          color: #000000;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .crn-prt.type_jln_language.pattern_21 {
      ul {
        margin-left: -5px;
        margin-right: -5px;
        li {
          display: inline-block;
          vertical-align: top;
          width: 33.33%;
          padding: 5px;
          a {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            height: 44px;
            border: 1px solid var(--color-background-1);
            text-align: center;
          }
        }
        li.current {
          a {
            border-width: 2px;
            color: #333333;
          }
        }
      }
    }
    .crn-prt.type_jln_language.pattern_32 {
      ul {
        margin-left: -5px;
        margin-right: -5px;
        li {
          display: inline-block;
          vertical-align: top;
          width: 33.33%;
          padding: 5px;
          a {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            height: 44px;
            border: 1px solid var(--color-background-1);
            text-align: center;
            border: 1px solid var(--color-background-1);
          }
        }
        li.current {
          a {
            border-width: 2px;
            color: #333333;
            color: #ffffff;
            border-width: 2px;
            border-color: #ca9a34 !important;
          }
        }
      }
    }
    .crn-prtal2 {
      > .content {
        padding: 24px 40px;
      }
    }
    .crn-prt_hd11b {
      img {
        max-width: 150px;
        max-height: 448px;
      }
    }
    .crn-prti1 {
      // width: 1000px;
      height: 300px;
    }
    a.crn-prt_hd21b {
      display: inline-block;
      &:hover {
        opacity: 0.6;
      }
    }
    .crn-prt_hd11c {
      img {
        max-width: 150px;
        max-height: 238px;
      }
    }
    .crn-prt_fp_text_bg_none_1 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 32px;
          }
        }
      }
    }
    .crn-prt.type_mv {
      position: relative;
      overflow: hidden;
      margin: 0 auto;
      .slideshow-static {
        .header-slide {
          &:not(:first-child) {
            display: none;
          }
        }
      }
      .header-slide {
        position: absolute;
        top: 0;
        left: 0;
      }
      .slick-dots {
        position: absolute;
        text-align: center;
        width: 100%;
        bottom: 60px;
        z-index: 99;
        li {
          position: relative;
          display: inline-block;
          margin: 0 8px;
          button {
            font-size: 0;
            line-height: 0;
            height: 20px;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: transparent;
            margin-bottom: 5px;
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
            }
          }
        }
      }
    }
    .crn-prt.type_mv.pattern_2 {
      min-height: 510px;
      .header-slide {
        height: 100vh;
        width: 100vw;
      }
    }
    .crn-prt.type_mv.pattern_11 {
      margin: 0 auto;
      .header-image {
        float: left;
      }
    }
    .crn-prt.type_mv.pattern_21 {
      margin-bottom: 22px;
      overflow: visible;
      .header-slide {
        position: relative;
      }
      .slick-dots {
        bottom: -22px;
        li {
          margin: 0;
          button {
            width: 12px;
          }
        }
      }
    }
    .crn-prt.type_mv.pattern_21.before-init {
      .header-slide {
        &:not(:first-child) {
          display: none;
        }
      }
    }
    .crn-prtmv1 {
      .slick-dots {
        li {
          button {
            width: 24px;
            &:before {
              background-color: #ffffff;
              border: 1px solid var(--color-secondary);
              width: 24px;
              height: 4px;
            }
          }
        }
        li.slick-active {
          button {
            &:before {
              background-color: var(--color-secondary);
              border: 1px solid #ffffff;
            }
          }
        }
      }
    }
    .crn-prti2 {
      img {
        max-width: 312px;
        max-height: 234px;
      }
    }
    .crn-prtbtn4a {
      background-color: var(--color-secondary);
      width: 128px;
      &:hover {
        background-color: #dcd0b7;
      }
      .btn--body {
        color: #ffffff;
        line-height: 40px;
        width: 128px;
      }
    }
    .crn-prtbtn4a.pattern_2 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
        &:hover {
          box-shadow: 0 0 0 2px #dcd0b7;
        }
      }
    }
    .crn-prtbtn4a.pattern_21 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
        &:hover {
          box-shadow: 0 0 0 2px #dcd0b7;
        }
      }
    }
    .crn-prtmv2 {
      .slick-dots {
        li {
          button {
            width: 24px;
            &:before {
              background-color: #ffffff;
              border: 1px solid var(--color-secondary);
              width: 24px;
              height: 4px;
            }
          }
        }
        li.slick-active {
          button {
            &:before {
              background-color: var(--color-secondary);
              border: 1px solid #ffffff;
            }
          }
        }
      }
      // width: 1000px;
      height: 375px;
      .header-slide {
        // width: 1000px;
        height: 375px;
      }
    }
    .crn-prt_ft7 {
      padding: 16px 0 0 0;
    }
    .crn-prt.type_gallery.pattern_1 {
      li {
        display: inline-block;
        cursor: pointer;
        &:hover {
          opacity: 0.6;
        }
        &:not(.selected) {
          border: none;
        }
      }
      .gallery-main {
        margin: 0 auto;
        .gallery-caption {
          color: #4e4e4e;
          margin-bottom: 10px;
        }
        .gallery-caption.caption-pattern_1 {
          color: #ffffff;
        }
      }
      .gallery-main__image {
        margin-bottom: 10px;
        position: relative;
        &:hover {
          .gallery-main__image__left {
            display: block;
          }
          .gallery-main__image__right {
            display: block;
          }
        }
      }
      .gallery-main__image__left {
        display: none;
        cursor: pointer;
        position: absolute;
        top: 50%;
        margin-top: -24px;
        width: 26px;
        height: 48px;
        left: 24px;
        background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_left-63a57c7a2ddeccc578a03409cf0f6368fb8e27ecceebe783d33b2150f646d98c.png')
          transparent no-repeat;
        background-size: cover;
        &:hover {
          opacity: 0.6;
        }
      }
      .gallery-main__image__right {
        display: none;
        cursor: pointer;
        position: absolute;
        top: 50%;
        margin-top: -24px;
        width: 26px;
        height: 48px;
        right: 24px;
        background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_right-3dde2202371c49b1929c569c3d634734ae8efb99d64117fc0c2ad32ebd6797bd.png')
          transparent no-repeat;
        background-size: cover;
        &:hover {
          opacity: 0.6;
        }
      }
    }
    .crn-prt.type_gallery.pattern_21 {
      .gallery-thumbs {
        overflow-y: auto;
        width: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        li {
          display: inline-block;
          cursor: pointer;
        }
      }
      .gallery-main {
        .gallery-caption {
          font-size: 12px;
          color: #4e4e4e;
          margin-bottom: 10px;
        }
        .gallery-caption.caption-pattern_1 {
          color: #ffffff;
        }
      }
      .gallery-main__image {
        margin-bottom: 10px;
        position: relative;
      }
      .gallery-main__image__left {
        display: block;
        cursor: pointer;
        position: absolute;
        top: 50%;
        margin-top: -15px;
        width: 30px;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.5);
        background-repeat: no-repeat;
        background-size: 10px 15px;
        background-position: center;
        left: 0;
        background-image: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_left_sp-f62d2752a9f13679b99833af947f018e492ed3c0b6c243f4cd956e4266295e5b.png');
      }
      .gallery-main__image__right {
        display: block;
        cursor: pointer;
        position: absolute;
        top: 50%;
        margin-top: -15px;
        width: 30px;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.5);
        background-repeat: no-repeat;
        background-size: 10px 15px;
        background-position: center;
        right: 0;
        background-image: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_right_sp-b97a2c55be8a3e70cdfa0cb8b2144520e23d7d7d1e11bbb80513ba22b8247a0d.png');
      }
    }
    .crn-prt.type_gallery.pattern_11 {
      ul {
        width: 50%;
        float: left;
        .crn-prt {
          width: 100%;
          float: left;
          img {
            width: 100%;
          }
        }
      }
      .gallery__group {
        &:after {
          content: '';
          display: table;
          clear: both;
        }
        &:nth-child(odd) {
          ul {
            &:nth-child(2) {
              li {
                float: left;
                width: 50%;
              }
            }
          }
        }
        &:nth-child(even) {
          ul {
            &:nth-child(1) {
              li {
                float: left;
                width: 50%;
              }
            }
          }
        }
      }
      li {
        .crn-prt.type_image {
          cursor: pointer;
          &:hover {
            opacity: 0.6;
          }
        }
      }
      .is-sp {
        li {
          .crn-prt.type_image {
            &:hover {
              opacity: 1;
            }
          }
        }
      }
      .gallery-details {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10000;
        background: rgba(0, 0, 0, 0.7);
        overflow: auto;
      }
      .gallery-details__bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 90%;
        width: calc(100% - 80px);
        height: 100%;
      }
      .gallery-details__inner {
        position: relative;
        width: 960px;
        margin: 68px auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
      .gallery-details__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        z-index: 11;
        position: relative;
        background: #ffffff;
      }
      .gallery-details__img {
        min-height: 480px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .crn-prt.type_image {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
        }
      }
      .gallery-details__details {
        padding: 20px;
      }
      .gallery-details__left {
        z-index: 100;
        cursor: pointer;
        position: absolute;
        top: 246px;
        width: 180px;
        height: 200px;
        left: 0;
        &:hover {
          opacity: 0.6;
        }
        &:after {
          position: absolute;
          content: '';
          display: table;
          top: 76px;
          width: 26px;
          height: 48px;
          left: 26px;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_left-63a57c7a2ddeccc578a03409cf0f6368fb8e27ecceebe783d33b2150f646d98c.png')
            transparent no-repeat;
          background-size: cover;
        }
      }
      .gallery-details__right {
        z-index: 100;
        cursor: pointer;
        position: absolute;
        top: 246px;
        width: 180px;
        height: 200px;
        right: 0;
        &:hover {
          opacity: 0.6;
        }
        &:after {
          position: absolute;
          content: '';
          display: table;
          top: 76px;
          width: 26px;
          height: 48px;
          right: 26px;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_right-3dde2202371c49b1929c569c3d634734ae8efb99d64117fc0c2ad32ebd6797bd.png')
            transparent no-repeat;
          background-size: cover;
        }
      }
      .gallery-details__close {
        position: absolute;
        cursor: pointer;
        top: -42px;
        right: 0;
        width: 24px;
        height: 24px;
        &:hover {
          opacity: 0.6;
        }
        span {
          display: block;
          position: absolute;
          top: 11px;
          left: -2px;
          width: 29px;
          height: 3px;
          background: #fff;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          &:nth-child(2) {
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
      }
    }
    .crn-prt.type_gallery.pattern_12 {
      li {
        display: inline-block;
        vertical-align: top;
        .crn-prt.type_image {
          cursor: pointer;
          &:hover {
            opacity: 0.6;
          }
        }
      }
      .is-sp {
        li {
          .crn-prt.type_image {
            &:hover {
              opacity: 1;
            }
          }
        }
      }
      .gallery-details {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10000;
        background: rgba(0, 0, 0, 0.7);
        overflow: auto;
      }
      .gallery-details__bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 90%;
        width: calc(100% - 80px);
        height: 100%;
      }
      .gallery-details__inner {
        position: relative;
        width: 960px;
        margin: 68px auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
      .gallery-details__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        z-index: 11;
        position: relative;
        background: #ffffff;
      }
      .gallery-details__img {
        min-height: 480px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .crn-prt.type_image {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
        }
      }
      .gallery-details__details {
        padding: 20px;
      }
      .gallery-details__left {
        z-index: 100;
        cursor: pointer;
        position: absolute;
        top: 246px;
        width: 180px;
        height: 200px;
        left: 0;
        &:hover {
          opacity: 0.6;
        }
        &:after {
          position: absolute;
          content: '';
          display: table;
          top: 76px;
          width: 26px;
          height: 48px;
          left: 26px;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_left-63a57c7a2ddeccc578a03409cf0f6368fb8e27ecceebe783d33b2150f646d98c.png')
            transparent no-repeat;
          background-size: cover;
        }
      }
      .gallery-details__right {
        z-index: 100;
        cursor: pointer;
        position: absolute;
        top: 246px;
        width: 180px;
        height: 200px;
        right: 0;
        &:hover {
          opacity: 0.6;
        }
        &:after {
          position: absolute;
          content: '';
          display: table;
          top: 76px;
          width: 26px;
          height: 48px;
          right: 26px;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_right-3dde2202371c49b1929c569c3d634734ae8efb99d64117fc0c2ad32ebd6797bd.png')
            transparent no-repeat;
          background-size: cover;
        }
      }
      .gallery-details__close {
        position: absolute;
        cursor: pointer;
        top: -42px;
        right: 0;
        width: 24px;
        height: 24px;
        &:hover {
          opacity: 0.6;
        }
        span {
          display: block;
          position: absolute;
          top: 11px;
          left: -2px;
          width: 29px;
          height: 3px;
          background: #fff;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          &:nth-child(2) {
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
      }
    }
    .crn-prt.type_gallery.pattern_22 {
      li {
        .crn-prt.type_image {
          cursor: pointer;
          &:hover {
            opacity: 0.6;
          }
        }
        width: 84px;
        height: 63px;
        display: inline-block;
        margin-right: 4px;
        div {
          width: 100%;
        }
        img {
          width: 100%;
        }
      }
      .is-sp {
        li {
          .crn-prt.type_image {
            &:hover {
              opacity: 1;
            }
          }
        }
      }
      .gallery-details {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10000;
        background: rgba(0, 0, 0, 0.7);
        overflow: auto;
      }
      .gallery-details__bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 90%;
        width: calc(100% - 80px);
        height: 100%;
      }
      .gallery-details__inner {
        position: relative;
        width: 960px;
        margin: 68px auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
      .gallery-details__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        z-index: 11;
        position: relative;
        background: #ffffff;
      }
      .gallery-details__img {
        min-height: 480px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .crn-prt.type_image {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
        }
      }
      .gallery-details__details {
        padding: 20px;
      }
      .gallery-details__left {
        z-index: 100;
        cursor: pointer;
        position: absolute;
        top: 246px;
        width: 180px;
        height: 200px;
        left: 0;
        &:hover {
          opacity: 0.6;
        }
        &:after {
          position: absolute;
          content: '';
          display: table;
          top: 76px;
          width: 26px;
          height: 48px;
          left: 26px;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_left-63a57c7a2ddeccc578a03409cf0f6368fb8e27ecceebe783d33b2150f646d98c.png')
            transparent no-repeat;
          background-size: cover;
        }
      }
      .gallery-details__right {
        z-index: 100;
        cursor: pointer;
        position: absolute;
        top: 246px;
        width: 180px;
        height: 200px;
        right: 0;
        &:hover {
          opacity: 0.6;
        }
        &:after {
          position: absolute;
          content: '';
          display: table;
          top: 76px;
          width: 26px;
          height: 48px;
          right: 26px;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_right-3dde2202371c49b1929c569c3d634734ae8efb99d64117fc0c2ad32ebd6797bd.png')
            transparent no-repeat;
          background-size: cover;
        }
      }
      .gallery-details__close {
        position: absolute;
        cursor: pointer;
        top: -42px;
        right: 0;
        width: 24px;
        height: 24px;
        &:hover {
          opacity: 0.6;
        }
        span {
          display: block;
          position: absolute;
          top: 11px;
          left: -2px;
          width: 29px;
          height: 3px;
          background: #fff;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          &:nth-child(2) {
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
      }
      ul {
        width: auto;
        overflow-x: auto;
        white-space: nowrap;
      }
    }
    .crn-prt.type_gallery {
      .gallery-details-pattern21 {
        .gallery-details__inner {
          width: 100%;
        }
        .gallery-details__bg {
          width: 100%;
        }
        .gallery-details__content {
          width: 292px;
          margin: 0 auto;
          background-color: transparent;
          margin: 0;
        }
        .gallery-details {
          _ {
            &:lang(x) {
              &::-ms-backdrop {
                margin: 0;
              }
            }
          }
        }
        .gallery-details__img {
          min-height: 1px;
          position: relative;
        }
        .gallery-details__close {
          top: -28px;
          width: 18px;
          height: 18px;
          span {
            top: 9px;
            width: 23px;
            height: 2px;
          }
        }
        .swipe-list {
          border-radius: 5px;
          overflow: hidden;
          background-color: white;
        }
        .gallery-main__image {
          position: relative;
          .type_image {
            width: 292px;
            height: 219px;
          }
        }
        .gallery-main__image__left {
          display: block;
          cursor: pointer;
          position: absolute;
          top: 50%;
          margin-top: -15px;
          width: 30px;
          height: 30px;
          background-color: rgba(0, 0, 0, 0.5);
          background-repeat: no-repeat;
          background-size: 10px 15px;
          background-position: center;
          left: 0;
          background-image: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_left_sp-f62d2752a9f13679b99833af947f018e492ed3c0b6c243f4cd956e4266295e5b.png');
        }
        .gallery-main__image__right {
          display: block;
          cursor: pointer;
          position: absolute;
          top: 50%;
          margin-top: -15px;
          width: 30px;
          height: 30px;
          background-color: rgba(0, 0, 0, 0.5);
          background-repeat: no-repeat;
          background-size: 10px 15px;
          background-position: center;
          right: 0;
          background-image: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_right_sp-b97a2c55be8a3e70cdfa0cb8b2144520e23d7d7d1e11bbb80513ba22b8247a0d.png');
        }
      }
    }
    .crn-prtg1 {
      width: 100%;
      .gallery-main {
        width: 100%;
        .crn-prt {
          width: 100%;
          height: 500px;
        }
      }
      li {
        .crn-prt {
          width: 90px;
          height: 45px;
        }
        &:not(:nth-child(10n)) {
          margin-right: 11px;
        }
      }
      li.selected {
        .crn-prt {
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 3px solid var(--color-secondary);
          }
        }
      }
    }
    a.crn-prti3 {
      &:hover {
        opacity: 0.6;
      }
    }
    .crn-prti3 {
      img {
        max-width: 1000px;
      }
    }
    .crn-prt_hd3b {
      a {
        &:hover {
          opacity: 0.6;
        }
      }
      li {
        max-height: 156px;
        padding: 10px 20px;
      }
    }
    .crn-prtbtn4b {
      background-color: var(--color-secondary);
      width: 40px;
      &:hover {
        background-color: #dcd0b7;
      }
      .btn--body {
        color: #ffffff;
        line-height: 40px;
        width: 40px;
      }
    }
    .crn-prtbtn4b.pattern_2 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
        &:hover {
          box-shadow: 0 0 0 2px #dcd0b7;
        }
      }
    }
    .crn-prtbtn4b.pattern_21 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
        &:hover {
          box-shadow: 0 0 0 2px #dcd0b7;
        }
      }
    }
    .crn-prtls1 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 64px;
          }
        }
      }
    }
    .crn-prtmv3 {
      .slick-dots {
        li {
          button {
            width: 24px;
            &:before {
              background-color: #ffffff;
              border: 1px solid var(--color-secondary);
              width: 24px;
              height: 4px;
            }
          }
        }
        li.slick-active {
          button {
            &:before {
              background-color: var(--color-secondary);
              border: 1px solid #ffffff;
            }
          }
        }
      }
      width: 100%;
      height: 347px;
      .header-slide {
        width: 100%;
        height: 347px;
      }
    }
    .crn-prtg2 {
      width: 600px;
      .gallery-main {
        width: 100%;
        .crn-prt {
          width: 100%;
          height: 450px;
        }
      }
      li {
        .crn-prt {
          width: 51px;
          height: 38px;
        }
        &:not(:nth-child(10n)) {
          margin-right: 10px;
        }
      }
      li.selected {
        .crn-prt {
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 3px solid var(--color-secondary);
          }
        }
      }
    }
    a.crn-prti4 {
      &:hover {
        opacity: 0.6;
      }
    }
    .crn-prti4 {
      img {
        max-width: 100%;
        max-height: 318px;
      }
    }
    .crn-prtbl5 {
      > .crn-prt {
        &:not(:last-child) {
          margin-right: 32px;
        }
      }
    }
    .crn-prtls2 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 64px;
            border-bottom: 1px solid #cccccc;
            padding-bottom: 64px;
          }
        }
      }
    }
    .crn-prtmv4 {
      .slick-dots {
        li {
          button {
            width: 24px;
            &:before {
              background-color: #ffffff;
              border: 1px solid var(--color-secondary);
              width: 24px;
              height: 4px;
            }
          }
        }
        li.slick-active {
          button {
            &:before {
              background-color: var(--color-secondary);
              border: 1px solid #ffffff;
            }
          }
        }
      }
      width: 100%;
      height: 356px;
      .header-slide {
        width: 100%;
        height: 356px;
      }
    }
    a.crn-prt_ft21 {
      &:hover {
        opacity: 0.6;
      }
    }
    .crn-prtg3 {
      ul {
        .crn-prt {
          margin-bottom: 6px;
        }
        &:nth-child(odd) {
          padding-right: 4px;
        }
        &:nth-child(even) {
          padding-left: 4px;
        }
      }
      .gallery__group {
        &:nth-child(odd) {
          ul {
            &:nth-child(2) {
              li {
                &:nth-child(odd) {
                  padding-right: 4px;
                }
                &:nth-child(even) {
                  padding-left: 4px;
                }
              }
            }
          }
        }
        &:nth-child(even) {
          ul {
            &:nth-child(1) {
              li {
                &:nth-child(odd) {
                  padding-right: 4px;
                }
                &:nth-child(even) {
                  padding-left: 4px;
                }
              }
            }
          }
        }
      }
    }
    .crn-prti5 {
      img {
        max-width: 960px;
        max-height: 690px;
      }
    }
    .crn-prtbtn2b {
      background-color: var(--color-secondary);
      &:hover {
        background-color: #dcd0b7;
      }
      .btn--body {
        color: #ffffff;
        line-height: 20px;
        padding-left: 50px;
        padding-right: 50px;
      }
    }
    .crn-prtbtn2b.pattern_2 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
        &:hover {
          box-shadow: 0 0 0 2px #dcd0b7;
        }
      }
    }
    .crn-prtbtn2b.pattern_21 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
        &:hover {
          box-shadow: 0 0 0 2px #dcd0b7;
        }
      }
    }
    .crn-prt.type_jln_news_list {
      position: relative;
      &:after {
        content: ' ';
        display: table;
        clear: both;
      }
      .date {
        width: 100px;
        font-size: 12px;
        display: inline-block;
      }
      .type {
        display: inline-block;
        .news-type {
          width: 90px;
          display: inline-block;
          text-align: center;
          font-size: 12px;
        }
      }
      .title {
        display: inline-block;
        margin-left: 16px;
        font-size: 16px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .crn-prt.type_jln_news_list.pattern_1 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      .date {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100px;
        flex: 0 0 100px;
        line-height: 1.5;
        letter-spacing: 0;
      }
      .type {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 90px;
        flex: 0 0 90px;
        .news-type {
          line-height: 1.5;
          letter-spacing: 0;
        }
      }
    }
    .crn-prt.type_jln_news_list.pattern_31 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      .date {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100px;
        flex: 0 0 100px;
        line-height: 1.5;
        letter-spacing: 0;
      }
      .type {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 90px;
        flex: 0 0 90px;
        .news-type {
          line-height: 1.5;
          letter-spacing: 0;
        }
      }
      .title {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .crn-prt.type_jln_news_list.pattern_21 {
      .date {
        width: 90px;
        margin-bottom: 10px;
      }
      .type {
        margin-bottom: 10px;
      }
      .title {
        font-size: 14px;
        display: block;
        margin-left: 0;
        margin-right: 20px;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .crn-prtnews_title {
      .title {
        color: #2e518b;
      }
      .news-type.type--1 {
        background-color: #ece6da;
      }
      .news-type.type--2 {
        background-color: #dae2e0;
      }
    }
    .crn-prtls3 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
    .crn-prt_ft11 {
      padding: 0 12px 0 0;
    }
    a.crn-prt_ft22 {
      &:hover {
        opacity: 0.6;
      }
    }
    .crn-prtg4 {
      ul {
        &:not(:first-child) {
          margin: 0 -12.5px;
          .crn-prt.type_image {
            width: 100%;
            height: 135px;
            margin-bottom: 10px;
          }
          li {
            width: 205px;
            padding: 0 12.5px 10px;
          }
        }
        &:first-child {
          margin: 0 -13px;
          .crn-prt.type_image {
            width: 100%;
            height: 237px;
            margin-bottom: 10px;
          }
          li {
            width: 342px;
            padding: 0 13px 10px;
          }
        }
      }
    }
    .crn-prti6 {
      img {
        max-width: 424px;
        max-height: 318px;
      }
    }
    .crn-prt.type_page_top {
      position: fixed;
      bottom: 20px;
      right: 20px;
      cursor: pointer;
      z-index: 2000;
      &:hover {
        opacity: 0.6;
      }
    }
    .crn-prt.type_page_top.pattern_31 {
      background-color: #ca9a34;
      &:hover {
        opacity: 1;
        background-color: #ddb768;
      }
    }
    .crn-prt.type_page_top.tripai-cntrl-hidden {
      display: none;
    }
    .crn-prttbl3b {
      th {
        padding-top: 12px;
        padding-bottom: 12px;
        width: 140px;
        padding-right: 30px;
      }
      td {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
    .crn-prtls4 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
    a.crn-prt_ft23 {
      &:hover {
        opacity: 0.6;
      }
    }
    .crn-prti7 {
      img {
        max-width: 600px;
        max-height: 450px;
      }
    }
    .crn-prttbl1a {
      th {
        padding-top: 12px;
        padding-bottom: 12px;
        width: 220px;
        padding-right: 40px;
      }
      td {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
    .crn-prtls5 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 24px;
          }
        }
      }
    }
    a.crn-prt_ft24 {
      &:hover {
        opacity: 0.6;
      }
    }
    .crn-prttbl1b {
      th {
        padding-top: 12px;
        padding-bottom: 12px;
        width: 220px;
        padding-right: 40px;
      }
      td {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
    .crn-prtls6 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 40px;
          }
        }
      }
    }
    .crn-prti9 {
      img {
        max-width: 100%;
      }
    }
    .crn-prttbl1c {
      th {
        padding-top: 12px;
        padding-bottom: 12px;
        width: 220px;
        padding-right: 40px;
      }
      td {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
    .crn-prthl6b {
      a {
        display: inline-block;
      }
    }
    .crn-prttbl1d {
      th {
        padding-top: 12px;
        padding-bottom: 12px;
        width: 220px;
        padding-right: 40px;
      }
      td {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
    .crn-prthl5 {
      a {
        display: inline-block;
      }
    }
    .crn-prt_a4 {
      border-width: 1px;
      border-color: #efefef;
      > .content {
        padding: 40px 36px;
      }
    }
    .crn-prthl4b {
      a {
        display: inline-block;
      }
    }
    .crn-prthl6d {
      a {
        display: inline-block;
      }
    }
    .crn-prt_fp_text_image_tile_image_top_1 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 32px;
          }
        }
      }
    }
    .crn-prtt10 {
      .text--more {
        color: #2e518b;
      }
    }
    .crn-prthl3b {
      a {
        display: inline-block;
      }
    }
    .crn-prthl6e {
      a {
        display: inline-block;
      }
    }
    .crn-prt_fp_text_image_tile_image_top_2 {
      li {
        .inner {
          padding: 16px;
        }
        padding: 16px;
        width: 50%;
        .crn-prt {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
        a.column-wrap {
          &:hover {
            opacity: 0.6;
          }
        }
        .title {
          a {
            color: #2e518b;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      ul {
        margin: -16px;
      }
      .crn-prt.type_image {
        height: 363px;
      }
    }
    .crn-prttbl2 {
      th {
        white-space: nowrap;
        padding-top: 12px;
        padding-bottom: 12px;
        max-width: 220px;
        padding-right: 40px;
      }
      td {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
    a.crn-prti7b {
      &:hover {
        opacity: 0.6;
      }
    }
    .crn-prti7b {
      img {
        max-width: 600px;
        max-height: 450px;
      }
    }
    .crn-prttbl3 {
      th {
        padding-top: 12px;
        padding-bottom: 12px;
        width: 180px;
        padding-right: 40px;
      }
      td {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
    .crn-prtbtn1 {
      background-color: var(--color-secondary);
      &:hover {
        background-color: #dcd0b7;
      }
      .btn--body {
        color: #ffffff;
        line-height: 20px;
        padding-left: 50px;
        padding-right: 50px;
      }
    }
    .crn-prtbtn1.pattern_2 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
        &:hover {
          box-shadow: 0 0 0 2px #dcd0b7;
        }
      }
    }
    .crn-prtbtn1.pattern_21 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
        &:hover {
          box-shadow: 0 0 0 2px #dcd0b7;
        }
      }
    }
    .crn-prthl6g {
      a {
        display: inline-block;
      }
    }
    .crn-prttbl4 {
      th {
        padding-top: 12px;
        padding-bottom: 12px;
        width: 130px;
        padding-right: 30px;
      }
      td {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
    .crn-prtbtn2 {
      background-color: var(--color-secondary);
      &:hover {
        background-color: #dcd0b7;
      }
      .btn--body {
        color: #ffffff;
        line-height: 20px;
        padding-left: 50px;
        padding-right: 50px;
      }
    }
    .crn-prtbtn2.pattern_2 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
        &:hover {
          box-shadow: 0 0 0 2px #dcd0b7;
        }
      }
    }
    .crn-prtbtn2.pattern_21 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
        &:hover {
          box-shadow: 0 0 0 2px #dcd0b7;
        }
      }
    }
    .crn-prt_hd1 {
      > .content {
        padding: 8px 40px;
      }
    }
    .crn-prthl6h {
      a {
        display: inline-block;
      }
    }
    .crn-prttbl5 {
      th {
        padding-top: 12px;
        padding-bottom: 12px;
        width: 220px;
        padding-right: 40px;
      }
      td {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
    a.crn-prti4b {
      &:hover {
        opacity: 0.6;
      }
    }
    .crn-prti4b {
      img {
        max-width: 100%;
        max-height: 360px;
      }
    }
    .crn-prtg2b {
      width: 600px;
      .gallery-main {
        width: 100%;
        .crn-prt {
          width: 100%;
          height: 450px;
        }
      }
      li {
        .crn-prt {
          width: 51px;
          height: 38px;
        }
        &:not(:nth-child(10n)) {
          margin-right: 10px;
        }
      }
      li.selected {
        .crn-prt {
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 3px solid var(--color-secondary);
          }
        }
      }
    }
    .crn-prtbtn3 {
      background-color: var(--color-secondary);
      &:hover {
        background-color: #dcd0b7;
      }
      .btn--body {
        color: #ffffff;
        line-height: 40px;
        padding-left: 24px;
        padding-right: 24px;
      }
    }
    .crn-prtbtn3.pattern_2 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
        &:hover {
          box-shadow: 0 0 0 2px #dcd0b7;
        }
      }
    }
    .crn-prtbtn3.pattern_21 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
        &:hover {
          box-shadow: 0 0 0 2px #dcd0b7;
        }
      }
    }
    .crn-prt_fp_image_1 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 32px;
          }
        }
      }
    }
    a.crn-prt_hd2 {
      display: inline-block;
    }
    .crn-prt_fp_text_bg_color_1 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 32px;
          }
        }
      }
    }
    .crn-prtg1b {
      width: 100%;
      .gallery-main {
        width: 100%;
        .crn-prt {
          width: 100%;
          height: 500px;
        }
      }
      li {
        .crn-prt {
          width: 90px;
          height: 45px;
        }
        &:not(:nth-child(10n)) {
          margin-right: 11px;
        }
      }
      li.selected {
        .crn-prt {
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 3px solid var(--color-secondary);
          }
        }
      }
    }
    .crn-prtg2c {
      width: 600px;
      .gallery-main {
        width: 100%;
        .crn-prt {
          width: 100%;
          height: 450px;
        }
      }
      li {
        .crn-prt {
          width: 51px;
          height: 38px;
        }
        &:not(:nth-child(10n)) {
          margin-right: 10px;
        }
      }
      li.selected {
        .crn-prt {
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 3px solid var(--color-secondary);
          }
        }
      }
    }
    .crn-prt_hd3 {
      a {
        &:hover {
          opacity: 0.6;
        }
      }
      li {
        max-width: 14.285714285714286%;
      }
      li.current {
        &:after {
          border-bottom: 5px solid var(--color-secondary);
        }
      }
    }
    .crn-prtbtn5 {
      background-color: var(--color-secondary);
      width: 100%;
      &:hover {
        background-color: #dcd0b7;
      }
      .btn--body {
        color: #ffffff;
        line-height: 40px;
        width: 100%;
      }
    }
    .crn-prtbtn5.pattern_2 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
        &:hover {
          box-shadow: 0 0 0 2px #dcd0b7;
        }
      }
    }
    .crn-prtbtn5.pattern_21 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
        &:hover {
          box-shadow: 0 0 0 2px #dcd0b7;
        }
      }
    }
    .crn-prtmv2b {
      .slick-dots {
        li {
          button {
            width: 24px;
            &:before {
              background-color: #ffffff;
              border: 1px solid var(--color-secondary);
              width: 24px;
              height: 4px;
            }
          }
        }
        li.slick-active {
          button {
            &:before {
              background-color: var(--color-secondary);
              border: 1px solid #ffffff;
            }
          }
        }
      }
      // width: 1000px;
      height: 375px;
      .header-image {
        width: 250px;
        height: 187.5px;
      }
      .header-image__1 {
        margin-top: 187.5px;
        margin-left: -250px;
      }
      .header-image__2 {
        width: 500px;
        height: 375px;
      }
    }
    .crn-prt.type_jln_contact {
      .contact-row {
        margin-bottom: 20px;
        .contact-left {
          margin-bottom: 5px;
          min-width: 230px;
          margin-right: -30px;
          padding-right: 20px;
        }
        .contact-right {
          font-size: 16px;
          margin-left: 30px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      .contact-row.contact-chat {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        .contact-left {
          p {
            a {
              font-size: 18px;
              display: inline;
            }
          }
        }
        .contact-right {
          width: 100%;
          p {
            margin-bottom: 10px;
            padding-top: 2px;
          }
          ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            li {
              font-size: 14px;
              margin-right: 25px;
              vertical-align: middle;
              .svg-icon {
                font-size: 24px;
                padding: 1px;
                fill: #333;
                margin-right: 8px;
                vertical-align: -0.2em;
              }
              .svg-icon__bg {
                fill: #fff;
                opacity: 1;
              }
            }
          }
        }
      }
      .contact-row.contact-tel {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        .contact-left {
          margin-bottom: 0;
          p {
            font-size: 18px;
            font-weight: bold;
          }
        }
        .contact-right {
          p {
            padding-top: 5px;
          }
        }
      }
      .contact-row.contact-email {
        .contact-left {
          width: 100%;
          padding-right: 0;
          p {
            word-wrap: break-word;
            word-break: break-word;
            a {
              font-size: 16px;
            }
          }
        }
      }
    }
    .crn-prt.type_jln_contact.pattern_2 {
      .contact-row.contact-chat {
        .contact-right {
          ul {
            li {
              margin-right: 10px;
            }
            li.chat-list1 {
              &:before {
                margin-right: 5px;
              }
            }
            li.chat-list2 {
              &:before {
                margin-right: 5px;
              }
            }
            li.chat-list3 {
              &:before {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
    .crn-prt.type_jln_contact.pattern_21 {
      .contact-row {
        margin-bottom: 14px;
        .contact-right {
          font-size: 11px;
          margin-left: 22px;
        }
        .contact-left {
          font-weight: bold;
          margin-right: 0;
          padding-right: 0;
          .svg-icon {
            vertical-align: top;
            margin-top: 3px;
          }
        }
      }
      .contact-row.contact-desc {
        min-height: 21px;
      }
      .contact-row.contact-chat {
        display: block;
        .contact-left {
          p {
            a {
              font-size: 14px;
              display: inline;
            }
          }
        }
        .contact-right {
          width: auto;
          ul {
            display: block;
            li {
              margin-bottom: 4px;
              font-size: 10px;
              line-height: 24px;
              .svg-icon {
                font-size: 22px;
              }
            }
          }
        }
      }
      .contact-row.contact-tel {
        display: block;
        word-break: break-all;
        .contact-left {
          p {
            font-size: 14px;
            position: relative;
            padding-left: 21px;
          }
          .svg-icon {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        a {
          display: inline;
        }
      }
      .contact-row.contact-email {
        margin-bottom: 0;
        .contact-left {
          width: 100%;
          padding-right: 0;
          p {
            a {
              font-size: 12px;
            }
          }
        }
      }
    }
    .crn-prt_t4_content {
      .contact-left {
        p {
          a {
            color: #2e518b;
          }
        }
      }
    }
    .crn-prtal2_1 {
      ul {
        .crn-prt {
          max-width: 890px;
        }
      }
    }
    .crn-prt_fp_text_image_list_left_1 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 32px;
          }
        }
      }
    }
    a.crn-prt_hd21 {
      display: inline-block;
      &:hover {
        opacity: 0.6;
      }
    }
    .crn-prtbtn6 {
      background-color: var(--color-secondary);
      width: 136px;
      &:hover {
        background-color: #dcd0b7;
      }
      .btn--body {
        color: #ffffff;
        line-height: 42px;
        width: 136px;
      }
    }
    .crn-prtbtn6.pattern_2 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
        &:hover {
          box-shadow: 0 0 0 2px #dcd0b7;
        }
      }
    }
    .crn-prtbtn6.pattern_21 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
        &:hover {
          box-shadow: 0 0 0 2px #dcd0b7;
        }
      }
    }
    .crn-prt_hd5 {
      li {
        max-width: 130px;
        &:not(:last-child) {
          margin-right: 40px;
        }
      }
    }
    .crn-prt_hd11 {
      img {
        max-width: 570px;
        max-height: 72px;
      }
    }
    .crn-prt.type_label {
      display: inline-block;
      text-align: center;
    }
    .crn-prt.type_label.pattern_1 {
      border-style: solid;
      border-width: 1px;
      font-size: 10px;
      min-width: 48px;
      height: 20px;
      line-height: 18px;
      padding: 0 3px;
    }
    .crn-prt_fp_separator_solid {
      border-bottom: 1px solid #cccccc;
    }
    .crn-prtbx1 {
      border-width: 1px;
      border-color: #cccccc;
      > .content {
        padding: 40px 60px 32px;
      }
    }
    .crn-prtspr1 {
      border-bottom: 1px solid #cccccc;
    }
    .crn-prtbx2 {
      background-color: #ffffff;
      > .content {
        padding: 24px;
      }
    }
    .crn-prt_t1 {
      > ul {
        margin: 0 -20px;
        > li {
          padding: 0 20px;
          margin-bottom: 16px;
        }
      }
    }
    .crn-prt.type_jln_news_details {
      margin-bottom: 16px;
      .jln-news-details__type {
        display: inline-block;
        margin-bottom: 16px;
        .news-type {
          width: 90px;
          display: inline-block;
          text-align: center;
          font-size: 12px;
        }
      }
      .jln-news-details__title {
        font-weight: bold;
        font-size: 24px;
        line-height: 1.5;
        letter-spacing: 0;
      }
      .jln-news-details__contents {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
      }
      .jln-news-details__sns {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        line-height: 1.5;
        > div {
          padding-left: 10px;
        }
      }
      .jln-news-details__date {
        text-align: right;
      }
      .jln-news-details__info {
        > div {
          display: inline-block;
        }
      }
    }
    .crn-prt.type_jln_news_details.pattern_21 {
      .jln-news-details__type {
        margin-bottom: 10px;
        font-size: 12px;
      }
      .jln-news-details__title {
        font-size: 16px;
        margin-bottom: 10px;
        letter-spacing: -0.05em;
      }
      .jln-news-details__date {
        text-align: left;
        display: inline-block;
        margin-right: 6px;
        font-size: 11px;
        color: #767676;
      }
      .jln-news-details__contents {
        display: block;
        padding-bottom: 14px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
        font-size: 10px;
      }
      .jln-news-details__sns {
        margin-top: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
      .jln-news-details__info {
        > div {
          display: block;
        }
      }
    }
    .crn-prt.type_jln_news_details.pattern_32 {
      .jln-news-details__type {
        margin-bottom: 10px;
        font-size: 12px;
      }
      .jln-news-details__title {
        font-size: 16px;
        margin-bottom: 10px;
        letter-spacing: -0.05em;
      }
      .jln-news-details__date {
        text-align: left;
        display: inline-block;
        margin-right: 6px;
        font-size: 11px;
        color: #767676;
        color: #ffffff;
      }
      .jln-news-details__contents {
        display: block;
        padding-bottom: 14px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
        font-size: 10px;
      }
      .jln-news-details__sns {
        margin-top: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
      .jln-news-details__info {
        > div {
          display: block;
        }
      }
    }
    .crn-prt_nd2 {
      .news-type.type--1 {
        background-color: #ece6da;
      }
      .news-type.type--2 {
        background-color: #dae2e0;
      }
    }
    .crn-prt.type_jln_reviews {
      .jln-reviews__title {
        .jln-stars {
          margin-bottom: 16px;
          padding-right: 24px;
        }
      }
      .jln-reviews__title.with-border {
        margin-bottom: 16px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        .jln-stars {
          margin-bottom: 10px;
        }
        .jln-reviews__texts {
          margin-bottom: 10px;
        }
      }
      .jln-reviews__texts {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 16px;
      }
      .jln-reviews__txt1 {
        padding-right: 24px;
      }
      .jln-reviews__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }
      table {
        &:not(:last-child) {
          margin-right: 30px;
        }
      }
      th {
        font-size: 12px;
        text-align: left;
        vertical-align: top;
        &:first-child {
          padding-right: 14px;
        }
      }
      td {
        font-size: 12px;
        text-align: left;
        vertical-align: top;
        &:first-child {
          padding-right: 14px;
        }
      }
      tr {
        &:not(:last-child) {
          th {
            &:first-child {
              padding-bottom: 16px;
            }
          }
          td {
            &:first-child {
              padding-bottom: 16px;
            }
          }
        }
      }
    }
    .crn-prt.type_jln_reviews.pattern_1 {
      .jln-reviews__title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }
    }
    .crn-prt.type_jln_reviews.pattern_21 {
      .jln-reviews__title {
        .jln-stars {
          margin-top: 16px;
          margin-bottom: 6px;
          padding-right: 24px;
        }
      }
      .jln-reviews__texts {
        display: block;
        font-size: 10px;
      }
      th {
        font-weight: bold;
      }
    }
    .crn-prt_fp_main_image_1 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 32px;
          }
        }
      }
    }
    .crn-prt.type_jln_chat_ai {
      overflow: hidden;
      a {
        &:hover {
          opacity: 0.6;
        }
      }
    }
    .crn-prt.type_jln_chat_ai.pattern_2 {
      border-style: solid;
    }
    .crn-prt.type_jln_chat_ai.pattern_3 {
      border-style: solid;
      border-top: none;
    }
    .crn-prt_hd13 {
      border-width: 1px;
      border-color: var(--color-secondary);
      > a {
        > .content {
          padding: 4px 12px;
        }
      }
    }
    .crn-prtspr2 {
      border-bottom: 1px solid #cccccc;
    }
    .crn-prt_hd24 {
      .CalendarDay.CalendarDay--hovered-span {
        background: #d7caac;
      }
      .CalendarDay.CalendarDay--selected-span {
        background: #d7caac;
      }
      .CalendarDay.CalendarDay--blocked-calendar {
        background: #d7caac;
      }
      .calendar-staylength.is-pc.is-panel--true {
        border-bottom-color: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--hovered {
        background: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--selected {
        background: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--selected-start {
        background: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--selected-end {
        background: var(--color-secondary);
      }
      .tbd {
        a.tbd__none {
          color: #2e518b;
        }
      }
    }
    .crn-prtbx3 {
      border-width: 1px;
      border-color: var(--color-primary);
      > .content {
        padding: 40px;
      }
      > .title {
        > .crn-prt {
          padding: 16px 40px;
          background-color: var(--color-primary);
        }
      }
    }
    .crn-prtlnk_list1 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }
      }
    }
    a.crn-prt_t2 {
      &:hover {
        opacity: 0.6;
      }
    }
    .crn-prt_t2 {
      img {
        width: 220px;
      }
    }
    .crn-prt_nd3 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }
    .crn-prtlnk_list2 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }
      }
    }
    .crn-prthl6f2 {
      a {
        display: inline-block;
        width: 100%;
      }
    }
    .crn-prt_nd4 {
      li {
        .inner {
          padding: 16px;
        }
        padding: 16px;
        width: 50%;
        .crn-prt {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
      ul {
        margin: -16px;
      }
      .crn-prt.type_image {
        height: 363px;
      }
    }
    .crn-prt.type_jln_review_title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      .jln-review-title__title {
        font-size: 18px;
        font-weight: bold;
        margin-right: 20px;
      }
      .jln-review-title__sort-label {
        margin-right: 8px;
      }
      .jln-review-title__sort {
        > div {
          position: relative;
          background-color: #fff;
          border: 1px solid #333;
          min-width: 136px;
          .sort-select {
            cursor: pointer;
            font-size: 14px;
            background-color: transparent;
            border-style: none;
            color: inherit;
            width: 100%;
            padding: 7px;
            padding-right: 40px;
            height: 40px;
            line-height: 26px;
          }
          div.sort-select {
            cursor: auto;
          }
          &:before {
            content: '';
            display: block;
            z-index: 1;
            position: absolute;
            width: 16px;
            height: 9px;
            right: 15px;
            top: 15px;
            pointer-events: none;
            background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_down-5bdd393dafe6dda3899a01248184114dafb414a35b44223627d77eb99f22518a.png')
              transparent no-repeat;
            background-size: cover;
          }
        }
      }
    }
    .crn-prt.type_jln_review_title.pattern_31 {
      .jln-review-title__sort {
        > div {
          color: #000;
        }
      }
    }
    .crn-prt.type_jln_review_title.pattern_21 {
      display: block;
      text-align: left;
      position: relative;
      .jln-review-title__title {
        margin-bottom: 14px;
      }
      .jln-review-title__normal {
        font-weight: normal;
        font-size: 12px;
      }
      .jln-review-title__sort-label {
        position: absolute;
        width: 60px;
        top: 52px;
      }
      .jln-review-title__sort {
        padding-left: 60px;
        box-sizing: border-box;
        width: 100%;
      }
    }
    .crn-prt_s3 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 64px;
          }
        }
      }
    }
    .crn-prt_fp_table_left_bdr {
      border-width: 1px;
      border-color: #cccccc;
      > .content {
        padding: 40px 60px;
      }
    }
    .crn-prt_hd18a {
      width: 322px;
      padding: 10px 22px 12px;
      .CalendarDay.CalendarDay--hovered-span {
        background: #d7caac;
      }
      .CalendarDay.CalendarDay--selected-span {
        background: #d7caac;
      }
      .CalendarDay.CalendarDay--blocked-calendar {
        background: #d7caac;
      }
      .calendar-staylength.is-pc.is-panel--true {
        border-bottom-color: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--hovered {
        background: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--selected {
        background: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--selected-start {
        background: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--selected-end {
        background: var(--color-secondary);
      }
      .tbd {
        a.tbd__none {
          color: #2e518b;
        }
      }
      .calendar-open {
        left: 0;
      }
    }
    .crn-prthl6e2 {
      a {
        display: inline-block;
      }
    }
    .crn-prt_hd19b {
      width: 322px;
      > .content {
        padding: 6px 25px;
      }
    }
    @media only screen and (min-width: 1260px) {
      body {
        min-width: 1260px;
      }
      .sub-content {
        width: 100%;
        > .wrap {
          position: relative;
        }
        > .wrap.sub-contents {
          // width: 1200px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      section {
        .section-contents {
          // width: 1200px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      footer {
        > .wrap {
          // width: 1200px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .header {
        .header-part {
          .header-contents {
            // width: 1200px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
      .header.pattern_3 {
        .header-bottom {
          > .crn-prt {
            // width: 1200px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
      .crn-prt_t5 {
        .crn-prt.type_image {
          height: 220px;
        }
      }
      .crn-prtsc1b {
        .image {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 480px;
          flex: 0 0 480px;
          margin-right: 32px;
          width: 480px;
          max-width: 480px;
          .crn-prt.type_image {
            width: 480px;
          }
        }
      }
      .crn-prtsc1 {
        .image {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 480px;
          flex: 0 0 480px;
          margin-right: 32px;
          width: 480px;
          max-width: 480px;
          .crn-prt.type_image {
            width: 480px;
          }
        }
      }
      .crn-prtal1 {
        ul {
          .crn-prt {
            max-width: 1170px;
          }
        }
      }
      .crn-prti1 {
        // width: 1200px;
        height: 360px;
      }
      .crn-prti2 {
        img {
          max-width: 368px;
          max-height: 276px;
        }
      }
      .crn-prtmv2 {
        // width: 1200px;
        height: 450px;
        .header-slide {
          // width: 1200px;
          height: 450px;
        }
      }
      .crn-prtg1 {
        width: 100%;
        .gallery-main {
          width: 100%;
          .crn-prt {
            width: 100%;
            height: 600px;
          }
        }
        li {
          .crn-prt {
            width: 108px;
            height: 54px;
          }
          &:not(:nth-child(10n)) {
            margin-right: 12px;
          }
        }
      }
      .crn-prti3 {
        img {
          max-width: 1200px;
        }
      }
      .crn-prtmv3 {
        height: 450px;
        .header-slide {
          height: 450px;
        }
      }
      .crn-prti4 {
        img {
          max-width: 100%;
          max-height: 360px;
        }
      }
      .crn-prtmv4 {
        height: 450px;
        .header-slide {
          height: 450px;
        }
      }
      .crn-prtg4 {
        ul {
          &:not(:first-child) {
            margin: 0 -12.5px;
            .crn-prt.type_image {
              width: 100%;
              height: 165px;
              margin-bottom: 10px;
            }
            li {
              width: 245px;
              padding: 0 12.5px 10px;
            }
          }
          &:first-child {
            margin: 0 -12px;
            .crn-prt.type_image {
              width: 100%;
              height: 288px;
              margin-bottom: 10px;
            }
            li {
              width: 408px;
              padding: 0 12px 10px;
            }
          }
        }
      }
      .crn-prti6 {
        img {
          max-width: 480px;
          max-height: 360px;
        }
      }
      .crn-prti7 {
        img {
          max-width: 800px;
          max-height: 600px;
        }
      }
      .crn-prt_fp_text_image_tile_image_top_2 {
        .crn-prt.type_image {
          height: 438px;
        }
      }
      .crn-prti7b {
        img {
          max-width: 800px;
          max-height: 600px;
        }
      }
      .crn-prtg1b {
        width: 100%;
        .gallery-main {
          width: 100%;
          .crn-prt {
            width: 100%;
            height: 600px;
          }
        }
        li {
          .crn-prt {
            width: 108px;
            height: 54px;
          }
          &:not(:nth-child(10n)) {
            margin-right: 12px;
          }
        }
      }
      .crn-prtmv2b {
        // width: 1200px;
        height: 450px;
        .header-image {
          width: 300px;
          height: 225px;
        }
        .header-image__1 {
          margin-top: 225px;
          margin-left: -300px;
        }
        .header-image__2 {
          width: 600px;
          height: 450px;
        }
      }
      .crn-prtal2_1 {
        ul {
          .crn-prt {
            max-width: 1090px;
          }
        }
      }
      .crn-prt_hd11 {
        img {
          max-width: 770px;
        }
      }
      .crn-prt_nd4 {
        .crn-prt.type_image {
          height: 438px;
        }
      }
    }
  }

  @media only screen and (max-width: 960px) {
    body {
      /* font-family: 'ヒラギノ角ゴシック', 'Hiragino Sans', 'ヒラギノ角ゴ ProN W3',
        'Hiragino Kaku Gothic ProN', sans-serif; */
      font-size: 14px;
      line-height: 1.5;
      color: #333333;
      background-color: #fff;
      letter-spacing: -0.05em;
      min-width: 320px;
      a {
        color: #333333;
      }
    }
    .sub-content {
      width: 100%;
      > .wrap {
        position: relative;
      }
      > .wrap.sub-contents {
        margin-left: 14px;
        margin-right: 14px;
      }
    }
    .font_10 {
      font-size: 10px;
    }
    .font_11 {
      font-size: 11px;
    }
    .font_12 {
      font-size: 12px;
    }
    .font_13 {
      font-size: 13px;
    }
    .font_14 {
      font-size: 14px;
    }
    .font_15 {
      font-size: 15px;
    }
    .font_16 {
      font-size: 16px;
    }
    .font_17 {
      font-size: 17px;
    }
    .font_18 {
      font-size: 18px;
    }
    .font_20 {
      font-size: 20px;
    }
    .font_22 {
      font-size: 22px;
    }
    .font_24 {
      font-size: 24px;
    }
    .font_26 {
      font-size: 26px;
    }
    .font_28 {
      font-size: 28px;
    }
    .lh1 {
      line-height: 1.6;
    }
    .mb_0 {
      margin-bottom: 0px !important;
    }
    .mt_0 {
      margin-top: 0px !important;
    }
    .mb_2 {
      margin-bottom: 2px !important;
    }
    .mt_2 {
      margin-top: 2px !important;
    }
    .mb_3 {
      margin-bottom: 3px !important;
    }
    .mt_3 {
      margin-top: 3px !important;
    }
    .mb_6 {
      margin-bottom: 6px !important;
    }
    .mt_6 {
      margin-top: 6px !important;
    }
    .mb_7 {
      margin-bottom: 7px !important;
    }
    .mt_7 {
      margin-top: 7px !important;
    }
    .mb_8 {
      margin-bottom: 8px !important;
    }
    .mt_8 {
      margin-top: 8px !important;
    }
    .mb_9 {
      margin-bottom: 9px !important;
    }
    .mt_9 {
      margin-top: 9px !important;
    }
    .mb_10 {
      margin-bottom: 10px !important;
    }
    .mt_10 {
      margin-top: 10px !important;
    }
    .mb_12 {
      margin-bottom: 12px !important;
    }
    .mt_12 {
      margin-top: 12px !important;
    }
    .mb_14 {
      margin-bottom: 14px !important;
    }
    .mt_14 {
      margin-top: 14px !important;
    }
    .mb_16 {
      margin-bottom: 16px !important;
    }
    .mt_16 {
      margin-top: 16px !important;
    }
    .mb_18 {
      margin-bottom: 18px !important;
    }
    .mt_18 {
      margin-top: 18px !important;
    }
    .mb_20 {
      margin-bottom: 20px !important;
    }
    .mt_20 {
      margin-top: 20px !important;
    }
    .mb_22 {
      margin-bottom: 22px !important;
    }
    .mt_22 {
      margin-top: 22px !important;
    }
    .mb_24 {
      margin-bottom: 24px !important;
    }
    .mt_24 {
      margin-top: 24px !important;
    }
    .mb_30 {
      margin-bottom: 30px !important;
    }
    .mt_30 {
      margin-top: 30px !important;
    }
    .mb_32 {
      margin-bottom: 32px !important;
    }
    .mt_32 {
      margin-top: 32px !important;
    }
    .mb_40 {
      margin-bottom: 40px !important;
    }
    .mt_40 {
      margin-top: 40px !important;
    }
    .mb_43 {
      margin-bottom: 43px !important;
    }
    .mt_43 {
      margin-top: 43px !important;
    }
    .mb_64 {
      margin-bottom: 64px !important;
    }
    .mt_64 {
      margin-top: 64px !important;
    }
    .bg--06_b {
      // background-image: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/bg/06_b-84ea341dc17fb929e174594c0dd1deeb38c18c2f6a6c26809d3fe5a26c62fbd8.png');
      background-repeat: repeat;
    }
    .bg--06_a {
      // background-image: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/bg/06_a_3-1726dae4dc1bd9ebe9b7d4f039364801472971727eeb83557d75fce584196ab0.png');
      background-repeat: repeat;
    }
    .bg--06_title_s {
      // background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/bg/06_title_1-97ed885a82d9a670c8bb58fae52b5518e0f819bf4ad2cf45875c86c28e301b39.svg')
      //   repeat-X bottom center;
      padding: 0 10px 12px 10px;
      display: inline-block;
    }
    .c__t5 {
      color: #ff6600;
      a {
        color: #ff6600;
      }
    }
    .c__t6 {
      color: #e73656;
      a {
        color: #e73656;
      }
    }
    .c__t7 {
      color: #007ebe;
      a {
        color: #007ebe;
      }
    }
    .c__t8 {
      color: #ffffff;
      a {
        color: #ffffff;
      }
    }
    a.l__l1 {
      color: #2e518b;
    }
    a.lh__l1 {
      &:hover {
        color: #2e518b;
      }
    }
    .l__l1--inner {
      a {
        color: #2e518b;
      }
    }
    .lh__l1--inner {
      a {
        &:hover {
          color: #2e518b;
        }
      }
    }
    .c__t9 {
      color: var(--color-title-text);
      a {
        color: var(--color-title-text);
      }
    }
    .bg__bg12 {
      background-color: #efefef;
    }
    .f__f1 {
      fill: #ffffff;
    }
    .f__f2 {
      fill: var(--color-secondary);
    }
    .f__f3 {
      fill: var(--color-background-1);
    }
    .bg__bg1 {
      background-color: var(--color-primary);
    }
    .f__f5 {
      fill: #000000;
    }
    .bg__bg2 {
      background-color: var(--color-secondary);
    }
    .b__b2 {
      border-color: var(--color-background-1);
    }
    .f__f6 {
      fill: #ffffff;
    }
    .bg__bg3 {
      // background-color: var(--color-primary);
    }
    .bg__bg4 {
      background-color: rgba(46, 92, 79, 0.07);
    }
    .b__b4 {
      border-color: #efefef;
    }
    .bg__bg5 {
      background-color: #ffffff;
    }
    .b__b5 {
      border-color: var(--color-secondary);
    }
    .bg__bg6 {
      background-color: rgba(0, 0, 0, 0.5);
    }
    .c__t10 {
      color: var(--color-background-1);
      a {
        color: var(--color-background-1);
      }
    }
    .c__t21 {
      color: var(--color-secondary);
      a {
        color: var(--color-secondary);
      }
    }
    .c__t22 {
      color: #ffffff;
      a {
        color: #ffffff;
      }
    }
    .c__t1 {
      color: #333333;
      a {
        color: #333333;
      }
    }
    .c__t2 {
      color: #4e4e4e;
      a {
        color: #4e4e4e;
      }
    }
    .c__t3 {
      color: #767676;
      a {
        color: #767676;
      }
    }
    .c__t4 {
      color: #2e518b;
      a {
        color: #2e518b;
      }
    }
    section {
      width: 100%;
      .section-contents {
        margin-left: 14px;
        margin-right: 14px;
      }
      .has-overflow-contents.editable {
        &:before {
          margin-left: -14px;
          margin-right: -14px;
        }
      }
      > .wrap {
        overflow: hidden;
        padding: 0;
        .crn-cmp {
          margin-bottom: 0;
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &:first-child {
        > .wrap {
          .crn-cmp {
            &:first-child {
              margin-top: 30px;
            }
          }
        }
      }
      &:last-child {
        > .wrap {
          .crn-cmp {
            &:last-child {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
    section.has-overflow-contents {
      > .wrap {
        overflow: visible !important;
        &:after {
          content: '';
          display: table;
        }
      }
    }
    section.type_5 {
      > .wrap {
        .crn-cmp {
          &:first-child {
            margin-top: 30px;
          }
          &:last-child {
            margin-bottom: 30px;
          }
        }
      }
      &:nth-child(odd) {
        // background-image: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/bg/06_b-84ea341dc17fb929e174594c0dd1deeb38c18c2f6a6c26809d3fe5a26c62fbd8.png');
        background-repeat: repeat;
      }
      &:nth-child(even) {
        // background-image: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/bg/06_c-sp-4330060b2ebd5f6d06161c28684a8a4283db140a36e8b1ea165d79fd9cb84283.png');
        background-repeat: repeat;
        position: relative;
        &:after {
          display: block;
          content: '';
          width: 100%;
          height: 100%;
          /* background: -webkit-linear-gradient(
            left,
            rgba(246, 242, 235, 0) 0%,
            #f6f2eb 25%,
            #f6f2eb 75%,
            rgba(246, 242, 235, 0) 100%
          );
          background: linear-gradient(
            to right,
            rgba(246, 242, 235, 0) 0%,
            #f6f2eb 25%,
            #f6f2eb 75%,
            rgba(246, 242, 235, 0) 100%
          ); */
          position: absolute;
          right: 0;
          left: 0;
          top: 0;
          margin: auto;
          z-index: 1;
        }
        > div {
          position: relative;
          z-index: 2;
        }
      }
    }
    section.type_3 {
      > .wrap {
        border-right: 1px solid #000;
        border-left: 1px solid #000;
      }
    }
    section.type_4 {
      &:not(:last-of-type) {
        > .wrap {
          border-bottom: 1px solid #000;
        }
      }
    }
    section.type_7 {
      overflow: hidden;
    }
    section.type_8 {
      overflow: hidden;
      > .wrap {
        border: 1px solid #000;
      }
    }
    footer {
      width: 100%;
      overflow: hidden;
      > .wrap {
        margin-left: 14px;
        margin-right: 14px;
        position: relative;
        margin-top: 24px;
        margin-bottom: 24px;
      }
    }
    .header {
      position: relative;
      .header-inside {
        position: absolute;
        bottom: 80px;
        left: 0;
        width: 100%;
        z-index: 401;
        pointer-events: none;
        .crn-prt {
          pointer-events: auto;
        }
        .header-part {
          > .wrap {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;
          }
        }
      }
      .header-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 99;
      }
      .header-main {
        position: relative;
      }
      .header-part {
        .header-contents {
          margin-left: 14px;
          margin-right: 14px;
        }
      }
    }
    .header.pattern_2 {
      .header-top {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        pointer-events: none;
        > .header-part {
          pointer-events: auto;
        }
      }
    }
    .header.pattern_4 {
      * {
        min-height: 0;
        min-width: 0;
      }
      .mv-contents {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
      .mv-container {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        .crn-prt {
          width: 100%;
        }
      }
      .mv-inside {
        width: 322px;
        position: relative;
      }
      .mv-inside__wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 20px;
        z-index: 500;
      }
    }
    .header.pattern_4.mv-reverse {
      .mv-contents {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
      }
    }
    .header.pattern_3 {
      .header-inside {
        .header-part {
          > .wrap {
            padding: 0 10px;
          }
        }
      }
      .header-bottom {
        > .crn-prt {
          margin-left: 14px;
          margin-right: 14px;
        }
      }
    }
    .header.pattern_11 {
      .header-part {
        > .wrap {
          width: 100% !important;
        }
      }
    }
    .flex {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .flex.align-start {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .flex.align-end {
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;
    }
    .flex.align-center {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .flex.align-stretch {
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }
    .flex.justify-sb {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    .flex.justify-c {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .flex.justify-end {
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }
    .flex.flow-column {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column;
      flex-flow: column;
    }
    .flex.wrap {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .decor {
      position: relative;
      &:after {
        position: absolute;
        display: block;
        content: '';
      }
    }
    .decor.decor--tl {
      &:after {
        top: 0;
        left: 0;
      }
    }
    .decor.decor--tc {
      &:after {
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
      }
    }
    .decor.decor--tr {
      &:after {
        top: 0;
        right: 0;
      }
    }
    .decor.decor--bl {
      &:after {
        bottom: 0;
        left: 0;
      }
    }
    .decor.decor--bc {
      &:after {
        bottom: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
      }
    }
    .decor.decor--br {
      &:after {
        bottom: 0;
        right: 0;
      }
    }
    .decor.decor--ml {
      &:after {
        top: 50%;
        left: 0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
      }
    }
    .decor.decor--mr {
      &:after {
        top: 50%;
        right: 0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
      }
    }
    .icon {
      position: relative;
      &:after {
        position: absolute;
        display: block;
        content: '';
        bottom: 0;
        left: 0;
      }
    }
    .icon.icon--google {
      width: 122px;
      height: 16px;
      background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/google_white-82c2b7ad57de43de1d37cddf80bb74d8518a40b5609636839693f465c6570e5b.png')
        transparent no-repeat;
      background-size: cover;
    }
    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .clearfix {
      &:after {
        content: '';
        display: block;
        visibility: hidden;
        height: 0;
        clear: both;
      }
    }
    .vertical {
      -ms-writing-mode: tb-rl;
      -webkit-writing-mode: vertical-rl;
      writing-mode: vertical-rl;
    }
    .crn-prt.type_block {
      position: relative;
    }
    .crn-prt.type_block.pattern_2 {
      > .crn-prt {
        display: inline-block;
      }
    }
    .crn-prt.type_block.pattern_21 {
      position: absolute;
      top: 0;
      left: 0;
    }
    .crn-prt.type_bread_crumb {
      li {
        display: inline;
        a {
          display: inline-block;
        }
      }
      li + li {
        &:before {
          content: '＞';
          margin: 0 7px;
        }
      }
    }
    .crn-prt.type_bread_crumb.pattern_31 {
      li {
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .crn-prt.type_bread_crumb.pattern_32 {
      li {
        a {
          text-decoration: underline;
        }
      }
    }
    .crn-prt.type_button {
      display: inline-block;
      text-align: center;
      cursor: pointer;
      font-weight: bold;
    }
    .crn-prt.type_button.pattern_1 {
      .btn--body {
        border: none;
      }
    }
    .crn-prt.type_button.pattern_2 {
      .btn--body {
        border-style: solid;
      }
    }
    .crn-prt.type_button.pattern_21 {
      .btn--body {
        border-style: solid;
      }
    }
    .crn-prt.type_button.align_right {
      float: right;
      &:after {
        content: '';
        display: table;
        clear: both;
      }
    }
    .crn-prt.type_nav {
      a {
        position: relative;
      }
      span {
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-all;
      }
    }
    .crn-prt.type_nav.pattern_1 {
      li {
        display: inline-block;
        vertical-align: top;
      }
    }
    .crn-prt.type_nav.pattern_2 {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }
      li {
        position: relative;
        text-align: center;
      }
      li.current {
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;
        }
      }
      a {
        display: table;
        table-layout: fixed;
        height: 58px;
        span {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
    .crn-prt.type_nav.pattern_3 {
      li {
        display: block;
      }
    }
    .crn-prt.type_nav.pattern_4 {
      li {
        a {
          display: inline-block;
          padding-bottom: 12px;
          position: relative;
        }
      }
      li.current {
        a {
          &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            width: 100%;
          }
        }
      }
    }
    .crn-prt.type_nav.pattern_21 {
      li {
        a {
          padding: 14px 38px 16px 14px;
        }
      }
    }
    .crn-prt.type_list.pattern_2 {
      > ul {
        > li {
          display: inline-block;
          vertical-align: top;
        }
      }
    }
    .crn-prt.type_list.pattern_3 {
      > ul {
        > li {
          display: inline-block;
          vertical-align: top;
        }
      }
    }
    .crn-prt.type_list.pattern_11 {
      ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        li {
          min-width: 0;
          .crn-prt {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .crn-prt.type_list.pattern_12 {
      ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        li {
          min-width: 0;
          .crn-prt {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .crn-prt.type_paging {
      a {
        display: inline-block;
      }
      .paging__wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        line-height: 16px;
      }
      .paging__icon {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          width: 9px;
          height: 16px;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/paging-987dd577527bd1cab4e8b530566f4c2fde8701aba85d3d90f984f3a3fa200b61.png')
            transparent repeat;
        }
      }
      .paging__first {
        margin-right: 20px;
        padding-left: 26px;
        &:before {
          left: 0;
          width: 18px;
          -webkit-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
      .paging__prev {
        padding-left: 17px;
        &:before {
          left: 0;
          -webkit-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
      .paging__last {
        margin-left: 20px;
        padding-right: 26px;
        &:before {
          right: 0;
          width: 18px;
        }
      }
      .paging__next {
        padding-right: 17px;
        position: relative;
        &:before {
          right: 0;
        }
      }
    }
    .crn-prt.type_paging.pattern_1 {
      .paging__nav-prev {
        width: 270px;
        text-align: left;
      }
      .paging__pager {
        width: 100px;
        text-align: center;
      }
      .paging__nav-next {
        width: 270px;
        text-align: right;
      }
    }
    .crn-prt.type_paging.pattern_31 {
      .paging__nav-prev {
        width: 270px;
        text-align: left;
      }
      .paging__pager {
        width: 100px;
        text-align: center;
      }
      .paging__nav-next {
        width: 270px;
        text-align: right;
      }
      .paging__icon {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/paging_white-1219d6061bfd24a13ca2d39425e161636836ef297a0daad2cdffae442e8594cc.png')
            transparent repeat;
        }
      }
    }
    .crn-prt.type_paging.pattern_21 {
      .paging__wrapper {
        padding: 10px;
        position: relative;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
      }
      .paging__archive_wrapper {
        display: block;
        border-bottom: 1px solid #ccc;
        text-align: center;
      }
      .paging__archive {
        display: inline-block;
        padding: 10px;
        line-height: 16px;
      }
      .paging__pager {
        display: block;
        padding: 10px;
        text-align: center;
        line-height: 16px;
      }
      .paging__spr {
        height: 16px;
        width: 1px;
        background: #ccc;
      }
      .paging__nav-prev {
        margin-right: 20px;
      }
      .paging__nav-next {
        margin-left: 20px;
      }
      .paging__first {
        position: absolute;
        left: 10px;
      }
      .paging__last {
        position: absolute;
        right: 10px;
      }
    }
    .crn-prt.type_paging.pattern_32 {
      .paging__wrapper {
        padding: 10px;
        position: relative;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
      }
      .paging__archive_wrapper {
        display: block;
        border-bottom: 1px solid #ccc;
        text-align: center;
      }
      .paging__archive {
        display: inline-block;
        padding: 10px;
        line-height: 16px;
      }
      .paging__pager {
        display: block;
        padding: 10px;
        text-align: center;
        line-height: 16px;
      }
      .paging__spr {
        height: 16px;
        width: 1px;
        background: #ccc;
      }
      .paging__nav-prev {
        margin-right: 20px;
      }
      .paging__nav-next {
        margin-left: 20px;
      }
      .paging__first {
        position: absolute;
        left: 10px;
      }
      .paging__last {
        position: absolute;
        right: 10px;
      }
      .paging__icon {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/paging_white-1219d6061bfd24a13ca2d39425e161636836ef297a0daad2cdffae442e8594cc.png')
            transparent repeat;
        }
      }
    }
    .crn-prt.type_separator.editable {
      padding-bottom: 20px;
    }
    .crn-prt.type_table {
      width: 100%;
      th {
        font-weight: bold;
        text-align: left;
      }
    }
    .crn-prt.type_table.pattern_2 {
      th {
        text-align: right;
      }
    }
    .crn-prt.type_text {
      position: relative;
      .text--more {
        display: inline;
        cursor: pointer;
      }
      .text--more.is-pc {
        &:hover {
          text-decoration: underline;
        }
      }
      .text--toggle-pattern2.text--more {
        display: block;
        padding: 5px;
        border-style: solid;
        border-width: 1px;
        border-color: #dedede;
        text-align: center;
        position: relative;
        background-color: #fff;
        &:before {
          content: '';
          display: block;
          z-index: 1;
          position: absolute;
          width: 12px;
          height: 6px;
          right: 15px;
          top: 10px;
          pointer-events: none;
          background-size: cover;
        }
      }
      .text--toggle-pattern2.text--more.text--toggle-close {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_down-415dd1dd6d66785e8d87d38211d49e9e00fe96b43e706488976777cd33c9ba24.svg')
            transparent no-repeat;
        }
      }
      .text--toggle-pattern2.text--more.text--toggle-open {
        margin-top: 12px;
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_up-c2b08f78dbf45a22e1fc002bcc87f78114be35078bcb33929285b9e19a1365ce.svg')
            transparent no-repeat;
        }
      }
      .text--more-pattern1.text--more {
        text-decoration: underline;
      }
      .text--more-pattern2.text--more {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .crn-prt.type_text.pattern_11 {
      border-right: 1px solid;
    }
    .crn-prt.type_text.pattern_32 {
      .text--more {
        border-color: #555555;
        background-color: #272727;
      }
      .text--more.text--toggle-close {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_down_white-ddba43bda25f6b47dc5ede1567efabe1481d18d00ecf24ff95184529d345acc4.svg')
            transparent no-repeat;
        }
      }
      .text--more.text--toggle-open {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_up_white-aeff02e433d16c3f283a6974fac56ec1c5ad7870a0ce69d5f1fa5ce9c57cf73f.svg')
            transparent no-repeat;
        }
      }
    }
    .crn-prt.type_image {
      max-width: 100%;
      overflow: hidden;
      position: relative;
      img {
        vertical-align: top;
      }
      .image-caption {
        position: absolute;
        width: 100%;
        text-align: center;
        padding: 10px;
      }
      .image-caption.pattern_1 {
        top: 0;
      }
      .image-caption.pattern_2 {
        bottom: 0;
        line-height: 1.5;
        letter-spacing: 0;
      }
    }
    .crn-prt.type_image.pattern_2 {
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
    .crn-prt.type_image.pattern_3 {
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
      }
    }
    .crn-prt.type_image.pattern_6 {
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
    .crn-prt.type_image.pattern_4 {
      img {
        min-width: 100%;
        min-height: 100%;
      }
    }
    .crn-prt.type_image.pattern_5 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .crn-prt.type_image.pattern_21 {
      img {
        width: 100%;
        height: auto;
      }
    }
    .crn-prt.type_freepage {
      .fp-frame {
        margin-bottom: 32px;
      }
      .fp-frame.fp-dummy {
        text-align: center;
        font-size: 24px;
        padding: 20px;
      }
    }
    .crn-prt.type_jln_swipe_list.pattern_1 {
      .gallery-main__image {
        margin-bottom: 10px;
        position: relative;
      }
      .gallery-main__image__left {
        display: block;
        cursor: pointer;
        position: absolute;
        top: 50%;
        margin-top: -15px;
        width: 30px;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.5);
        background-repeat: no-repeat;
        background-size: 10px 15px;
        background-position: center;
        left: 0;
        background-image: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_left_sp-f62d2752a9f13679b99833af947f018e492ed3c0b6c243f4cd956e4266295e5b.png');
      }
      .gallery-main__image__right {
        display: block;
        cursor: pointer;
        position: absolute;
        top: 50%;
        margin-top: -15px;
        width: 30px;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.5);
        background-repeat: no-repeat;
        background-size: 10px 15px;
        background-position: center;
        right: 0;
        background-image: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_right_sp-b97a2c55be8a3e70cdfa0cb8b2144520e23d7d7d1e11bbb80513ba22b8247a0d.png');
      }
    }
    .crn-prt.type_single_column {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      .content {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
      }
      .image {
        .crn-prt.type_text {
          margin-top: 10px;
        }
      }
    }
    .crn-prtsc1b {
      .image {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 27.5%;
        flex: 0 0 27.5%;
        margin-right: 10px;
        width: 27.5%;
        max-width: 27.5%;
        .crn-prt.type_image {
          width: 100%;
        }
      }
    }
    .crn-prt_rvw5 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 30px;
            border-bottom: 1px solid #efefef;
            padding-bottom: 30px;
          }
        }
      }
    }
    .crn-prt.type_jln_qa {
      .jln-qa__category {
        text-align: right;
        a {
          display: inline-block;
        }
      }
      .jln-qa__question {
        padding-left: 48px;
        position: relative;
        font-size: 18px;
        line-height: 1.5;
        letter-spacing: 0;
        font-weight: bold;
        margin-bottom: 32px;
        .svg-icon {
          position: absolute;
          top: 0;
          left: 0;
        }
        h3 {
          margin-bottom: 12px;
        }
      }
      .jln-qa__answer {
        padding-left: 48px;
        position: relative;
        .svg-icon {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    .crn-prt.type_jln_qa.pattern_21 {
      .jln-qa__category {
        margin-bottom: 12px;
        font-size: 12px;
      }
      .jln-qa__question {
        padding-left: 0;
        font-size: 15px;
        margin-bottom: 14px;
        line-height: 22px;
        letter-spacing: -0.05em;
        h3 {
          padding-left: 28px;
        }
      }
      .jln-qa__answer {
        padding-left: 0;
        padding-left: 28px;
      }
    }
    .crn-prt.type_jln_qa.pattern_32 {
      .jln-qa__category {
        margin-bottom: 12px;
        font-size: 12px;
        a {
          text-decoration: underline;
        }
      }
      .jln-qa__question {
        padding-left: 0;
        font-size: 15px;
        margin-bottom: 14px;
        line-height: 22px;
        letter-spacing: -0.05em;
        h3 {
          padding-left: 28px;
        }
      }
      .jln-qa__answer {
        padding-left: 0;
        padding-left: 28px;
      }
    }
    .crn-prt.type_jln_qa.pattern_31 {
      .jln-qa__category {
        a {
          display: inline-block;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .crn-prtsc1 {
      .image {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 27.5%;
        flex: 0 0 27.5%;
        margin-right: 10px;
        width: 27.5%;
        max-width: 27.5%;
        .crn-prt.type_image {
          width: 100%;
        }
      }
    }
    .crn-prt.type_jln_review {
      position: relative;
      padding-top: 28px;
      .jln-review__icon {
        font-size: 48px;
        position: absolute;
        top: 0;
        left: 0;
      }
      .jln-review__date {
        position: absolute;
        top: 0;
        right: 0;
        color: #767676;
      }
      .jln-review__title {
        position: relative;
        padding-left: 56px;
        margin-bottom: 24px;
        h4 {
          font-size: 18px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: 0;
        }
      }
      .jln-review__ranks {
        float: left;
      }
      .jln-review__ranks__all {
        display: inline-block;
        color: #767676;
        margin-left: 20px;
      }
      .jln-review__desc {
        margin-bottom: 24px;
        font-size: 16px;
      }
      .jln-review__details {
        margin-bottom: 10px;
        > div {
          display: inline-block;
        }
      }
      .jln-review__details__label {
        width: 100px;
      }
      .jln-review__answer__box {
        margin: 32px 24px 0;
        border: 1px solid #ccc;
        padding: 16px;
      }
      .jln-review__answer__title {
        margin-bottom: 16px;
        line-height: 24px;
        &:after {
          content: '';
          display: block;
          visibility: hidden;
          height: 0;
          clear: both;
        }
      }
      .jln-review__answer__title__name {
        display: inline-block;
        position: relative;
        padding-left: 38px;
        font-weight: bold;
        .jln-review__icon {
          font-size: 24px;
        }
      }
      .jln-review__answer__title__at {
        float: right;
        color: #767676;
      }
    }
    .crn-prt.type_jln_review.pattern_21 {
      .jln-review__date {
        font-size: 10px;
      }
      .jln-review__icon {
        position: relative;
        float: left;
      }
      .jln-review__title {
        padding-left: 0px;
        h4 {
          font-size: 14px;
          margin-top: 12px;
          letter-spacing: -0.05em;
        }
      }
      .jln-review__ranks {
        margin-top: 9px;
        margin-left: 12px;
        .jln-stars {
          span {
            width: 20px;
            height: 30px;
            padding-top: 2.5px;
          }
          .svg-icon {
            font-size: 20px;
            height: 30px;
            svg {
              vertical-align: middle;
            }
          }
        }
      }
      .jln-review__ranks__all {
        font-size: 10px;
        clear: both;
        margin-left: 0px;
        display: block;
        padding-top: 12px;
      }
      .jln-review__details {
        font-size: 12px;
        margin-bottom: 10px;
        > div {
          display: inline;
        }
      }
      .jln-review__details__label {
        font-weight: bold;
        display: inline-block;
      }
      .jln-review__details__name {
        font-weight: bold;
        font-size: 13px;
      }
      .jln-review__desc {
        margin-bottom: 10px;
        font-size: 14px;
      }
      .jln-review__answer {
        margin-top: 20px;
        border: 1px solid #ccc;
        background-color: #fff;
      }
      .jln-review__answer__box {
        margin: 0;
        border: none;
        border-bottom: 1px solid #ccc;
        padding: 16px;
      }
      .jln-review__answer__toggle {
        text-align: center;
        padding: 8px;
        position: relative;
        &:before {
          content: '';
          display: block;
          z-index: 1;
          position: absolute;
          width: 16px;
          height: 8px;
          right: 15px;
          top: 14px;
          pointer-events: none;
          background-size: cover;
        }
      }
      .jln-review__answer__toggle.toggle-close {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_down-415dd1dd6d66785e8d87d38211d49e9e00fe96b43e706488976777cd33c9ba24.svg')
            transparent no-repeat;
        }
      }
      .jln-review__answer__toggle.toggle-open {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_up-c2b08f78dbf45a22e1fc002bcc87f78114be35078bcb33929285b9e19a1365ce.svg')
            transparent no-repeat;
        }
      }
      .jln-review__answer__title {
        display: block;
        position: relative;
        margin-bottom: 16px;
        line-height: 24px;
      }
      .jln-review__answer__title__name {
        position: relative;
        font-weight: bold;
        padding-left: 0px;
        padding-top: 28px;
        .jln-review__icon {
          display: none;
        }
      }
      .jln-review__answer__title__at {
        float: none;
        color: #767676;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 10px;
      }
    }
    .crn-prt.type_jln_review.pattern_32 {
      .jln-review__date {
        font-size: 10px;
        color: #ffffff;
      }
      .jln-review__icon {
        position: relative;
        float: left;
      }
      .jln-review__title {
        padding-left: 0px;
        h4 {
          font-size: 14px;
          margin-top: 12px;
          letter-spacing: -0.05em;
        }
      }
      .jln-review__ranks {
        margin-top: 9px;
        margin-left: 12px;
        .jln-stars {
          span {
            width: 20px;
            height: 30px;
            padding-top: 2.5px;
          }
          .svg-icon {
            font-size: 20px;
            height: 30px;
            svg {
              vertical-align: middle;
            }
          }
        }
      }
      .jln-review__ranks__all {
        font-size: 10px;
        clear: both;
        margin-left: 0px;
        display: block;
        padding-top: 12px;
        color: #ffffff;
      }
      .jln-review__details {
        font-size: 12px;
        margin-bottom: 10px;
        > div {
          display: inline;
        }
      }
      .jln-review__details__label {
        font-weight: bold;
        display: inline-block;
      }
      .jln-review__details__name {
        font-weight: bold;
        font-size: 13px;
      }
      .jln-review__desc {
        margin-bottom: 10px;
        font-size: 14px;
      }
      .jln-review__answer {
        margin-top: 20px;
        border: 1px solid #ccc;
        background-color: #fff;
        background-color: #272727;
      }
      .jln-review__answer__box {
        margin: 0;
        border: none;
        border-bottom: 1px solid #ccc;
        padding: 16px;
      }
      .jln-review__answer__toggle {
        text-align: center;
        padding: 8px;
        position: relative;
        &:before {
          content: '';
          display: block;
          z-index: 1;
          position: absolute;
          width: 16px;
          height: 8px;
          right: 15px;
          top: 14px;
          pointer-events: none;
          background-size: cover;
        }
      }
      .jln-review__answer__toggle.toggle-close {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_down-415dd1dd6d66785e8d87d38211d49e9e00fe96b43e706488976777cd33c9ba24.svg')
            transparent no-repeat;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_down_white-ddba43bda25f6b47dc5ede1567efabe1481d18d00ecf24ff95184529d345acc4.svg')
            transparent no-repeat;
        }
      }
      .jln-review__answer__toggle.toggle-open {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_up-c2b08f78dbf45a22e1fc002bcc87f78114be35078bcb33929285b9e19a1365ce.svg')
            transparent no-repeat;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_up_white-aeff02e433d16c3f283a6974fac56ec1c5ad7870a0ce69d5f1fa5ce9c57cf73f.svg')
            transparent no-repeat;
        }
      }
      .jln-review__answer__title {
        display: block;
        position: relative;
        margin-bottom: 16px;
        line-height: 24px;
      }
      .jln-review__answer__title__name {
        position: relative;
        font-weight: bold;
        padding-left: 0px;
        padding-top: 28px;
        .jln-review__icon {
          display: none;
        }
      }
      .jln-review__answer__title__at {
        float: none;
        color: #767676;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 10px;
        color: #ffffff;
      }
    }
    .crn-prt.type_jln_review.pattern_31 {
      .jln-review__date {
        color: #ffffff;
      }
      .jln-review__ranks__all {
        color: #ffffff;
      }
      .jln-review__answer {
        background-color: #272727;
      }
      .jln-review__answer__toggle.toggle-close {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_down_white-ddba43bda25f6b47dc5ede1567efabe1481d18d00ecf24ff95184529d345acc4.svg')
            transparent no-repeat;
        }
      }
      .jln-review__answer__toggle.toggle-open {
        &:before {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_up_white-aeff02e433d16c3f283a6974fac56ec1c5ad7870a0ce69d5f1fa5ce9c57cf73f.svg')
            transparent no-repeat;
        }
      }
      .jln-review__answer__title__at {
        color: #ffffff;
      }
    }
    .crn-prtsc2 {
      .image {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 27.5%;
        flex: 0 0 27.5%;
        margin-right: 6px;
        width: 27.5%;
        max-width: 27.5%;
        .crn-prt.type_image {
          width: 100%;
        }
      }
    }
    .crn-prt.type_jln_sp_archive {
      .jln_sp_archive {
        position: relative;
        background-color: #fff;
        border: 1px solid #333;
        min-width: 136px;
        text-align-last: center;
        .archive-select {
          cursor: pointer;
          font-size: 14px;
          background-color: transparent;
          border-style: none;
          color: inherit;
          width: 100%;
          padding: 7px;
          height: 40px;
          line-height: 26px;
        }
        div.archive-select {
          cursor: auto;
        }
        &:before {
          content: '';
          display: block;
          z-index: 1;
          position: absolute;
          width: 16px;
          height: 9px;
          right: 15px;
          top: 15px;
          pointer-events: none;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_down-5bdd393dafe6dda3899a01248184114dafb414a35b44223627d77eb99f22518a.png')
            transparent no-repeat;
          background-size: cover;
        }
      }
    }
    .crn-prtm1 {
      width: 100%;
      height: 400px;
    }
    .crn-prtlnk_list2b {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 14px;
          }
        }
      }
    }
    .crn-prtt8 {
      padding: 0 4px;
    }
    a.crn-prtt8 {
      display: inline-block;
    }
    .crn-prtt1b {
      .text--more {
        color: #2e518b;
      }
    }
    .crn-prt_hd8b {
      > .crn-prt {
        vertical-align: top;
      }
    }
    .crn-prt.type_jln_sp_shortcut {
      .jln_sp_shortcut {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        word-break: break-all;
        p {
          font-size: 10px;
          font-weight: bold;
        }
        .sc_icon {
          font-size: 22px;
          height: 33px;
          line-height: 33px;
        }
        .sc_title_inner {
          display: inline-block;
          text-align: left;
          padding: 0px 5px;
        }
        .svg-icon {
          height: 24px;
          vertical-align: middle;
          overflow: visible;
          margin-right: 0;
        }
        .map_block {
          .sc_icon {
            font-size: 14.6px;
          }
          .svg-icon--tall {
            height: 22px;
          }
        }
        .sc_block {
          display: block;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          -ms-flex-negative: 1;
          flex-shrink: 1;
          width: 100%;
          text-align: center;
          border-left-style: solid;
          border-left-width: 1px;
          &:first-child {
            border-left-style: none;
          }
        }
      }
    }
    .crn-prtshortcut {
      .jln_sp_shortcut {
        a {
          color: #2e518b;
        }
        .sc_block {
          border-left-color: #efefef;
        }
      }
    }
    .crn-prtal1 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
    .crn-prt_fp_tit_itm2 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }
    .crn-prtls10 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 6px;
          }
        }
      }
    }
    .crn-prtal2 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
    .crn-prtls11 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 14px;
            border-bottom: 1px solid #efefef;
            padding-bottom: 14px;
          }
        }
      }
    }
    .crn-prt.type_sp_menu {
      .sp-menu__btn {
        height: 34px;
        width: 30px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        margin-top: 13px;
        margin-right: 8px;
        span {
          margin-left: 5px;
          margin-right: 5px;
          display: block;
          position: absolute;
          top: 4px;
          left: 0px;
          width: 20px;
          height: 2px;
          background: #333;
          &:nth-child(2) {
            top: 11px;
          }
          &:nth-child(3) {
            top: 18px;
          }
        }
        div {
          top: 24px;
          font-size: 10px;
          position: absolute;
          width: 30px;
          height: 10px;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/sp_menu-9c790bc3b676967ae123630b3d8bf0b4a28ff41cd8124211680524036cfc6786.svg')
            transparent no-repeat;
          background-size: contain;
        }
      }
      .sp-menu__open {
        position: fixed;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        z-index: 5000;
        .sp-menu__btn {
          span {
            &:nth-child(1) {
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
              top: 11px;
            }
            &:nth-child(2) {
              display: none;
            }
            &:nth-child(3) {
              -webkit-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              transform: rotate(-45deg);
              top: 11px;
            }
          }
          div {
            background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/sp_menu_close-603c48f1abe54333c0f495dee0d447c21a98f4860869e6983eddc9b04ee6aea2.svg')
              transparent no-repeat;
            background-size: contain;
          }
        }
      }
    }
    .crn-prt.type_sp_menu.pattern_32 {
      .sp-menu__btn {
        span {
          background: #ffffff;
        }
        div {
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/sp_menu_bg_black-4ebd952a645711a297726f61f47622d3b09e5f2599f4ae50376708a6b69459c3.svg')
            transparent no-repeat;
          background-size: contain;
        }
      }
      .sp-menu__open {
        background: #1c1c1c;
        .sp-menu__btn {
          div {
            background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/sp_menu_close_bg_black-77b7e454ab604015fd30d38e360766841f4e233f262c65842e69d946ea13759a.svg')
              transparent no-repeat;
            background-size: contain;
          }
        }
      }
    }
    .crn-prti1 {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .crn-prtal3 {
      > ul {
        margin: 0 0px;
        > li {
          width: 50%;
          padding: 0 0px;
          margin-bottom: 0;
        }
      }
    }
    .crn-prt_hd5b {
      a {
        display: inline-block;
      }
      li {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
    .crn-prtls12 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 16px;
            border-bottom: 1px solid #efefef;
            padding-bottom: 16px;
          }
        }
      }
    }
    .crn-prt_fp_text_bg_none_1 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }
    .crn-prt.type_mv {
      position: relative;
      overflow: hidden;
      margin: 0 auto;
      .slideshow-static {
        .header-slide {
          &:not(:first-child) {
            display: none;
          }
        }
      }
      .header-slide {
        position: absolute;
        top: 0;
        left: 0;
      }
      .slick-dots {
        position: absolute;
        text-align: center;
        width: 100%;
        bottom: 60px;
        z-index: 99;
        li {
          position: relative;
          display: inline-block;
          margin: 0 8px;
          button {
            font-size: 0;
            line-height: 0;
            height: 20px;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: transparent;
            margin-bottom: 5px;
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
            }
          }
        }
      }
    }
    .crn-prt.type_mv.pattern_2 {
      min-height: 510px;
      .header-slide {
        height: 100vh;
        width: 100vw;
      }
    }
    .crn-prt.type_mv.pattern_11 {
      margin: 0 auto;
      .header-image {
        float: left;
      }
    }
    .crn-prt.type_mv.pattern_21 {
      margin-bottom: 22px;
      overflow: visible;
      .header-slide {
        position: relative;
      }
      .slick-dots {
        bottom: -22px;
        li {
          margin: 0;
          button {
            width: 12px;
          }
        }
      }
    }
    .crn-prt.type_mv.pattern_21.before-init {
      .header-slide {
        &:not(:first-child) {
          display: none;
        }
      }
    }
    .crn-prtmv1 {
      .slick-dots {
        li {
          button {
            width: 4px;
            &:before {
              background-color: #efefef;
              width: 4px;
              height: 4px;
            }
          }
        }
        li.slick-active {
          button {
            &:before {
              background-color: var(--color-secondary);
            }
          }
        }
      }
      width: 100%;
      height: 180px;
      .header-slide {
        width: 100%;
        height: 180px;
      }
    }
    .crn-prti11 {
      img {
        max-width: 100%;
      }
    }
    .crn-prti2 {
      img {
        max-width: 100%;
      }
    }
    .crn-prtbtn4a {
      background-color: var(--color-secondary);
      .btn--body {
        line-height: 32px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    .crn-prtbtn4a.pattern_2 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
      }
    }
    .crn-prtbtn4a.pattern_21 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
      }
    }
    a.crn-prt_ft30 {
      display: inline-block;
    }
    .crn-prt.type_gallery.pattern_1 {
      li {
        display: inline-block;
        cursor: pointer;
        &:hover {
          opacity: 0.6;
        }
        &:not(.selected) {
          border: none;
        }
      }
      .gallery-main {
        margin: 0 auto;
        .gallery-caption {
          color: #4e4e4e;
          margin-bottom: 10px;
        }
        .gallery-caption.caption-pattern_1 {
          color: #ffffff;
        }
      }
      .gallery-main__image {
        margin-bottom: 10px;
        position: relative;
        &:hover {
          .gallery-main__image__left {
            display: block;
          }
          .gallery-main__image__right {
            display: block;
          }
        }
      }
      .gallery-main__image__left {
        display: none;
        cursor: pointer;
        position: absolute;
        top: 50%;
        margin-top: -24px;
        width: 26px;
        height: 48px;
        left: 24px;
        background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_left-63a57c7a2ddeccc578a03409cf0f6368fb8e27ecceebe783d33b2150f646d98c.png')
          transparent no-repeat;
        background-size: cover;
        &:hover {
          opacity: 0.6;
        }
      }
      .gallery-main__image__right {
        display: none;
        cursor: pointer;
        position: absolute;
        top: 50%;
        margin-top: -24px;
        width: 26px;
        height: 48px;
        right: 24px;
        background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_right-3dde2202371c49b1929c569c3d634734ae8efb99d64117fc0c2ad32ebd6797bd.png')
          transparent no-repeat;
        background-size: cover;
        &:hover {
          opacity: 0.6;
        }
      }
    }
    .crn-prt.type_gallery.pattern_21 {
      .gallery-thumbs {
        overflow-y: auto;
        width: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        li {
          display: inline-block;
          cursor: pointer;
        }
      }
      .gallery-main {
        .gallery-caption {
          font-size: 12px;
          color: #4e4e4e;
          margin-bottom: 10px;
        }
        .gallery-caption.caption-pattern_1 {
          color: #ffffff;
        }
      }
      .gallery-main__image {
        margin-bottom: 10px;
        position: relative;
      }
      .gallery-main__image__left {
        display: block;
        cursor: pointer;
        position: absolute;
        top: 50%;
        margin-top: -15px;
        width: 30px;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.5);
        background-repeat: no-repeat;
        background-size: 10px 15px;
        background-position: center;
        left: 0;
        background-image: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_left_sp-f62d2752a9f13679b99833af947f018e492ed3c0b6c243f4cd956e4266295e5b.png');
      }
      .gallery-main__image__right {
        display: block;
        cursor: pointer;
        position: absolute;
        top: 50%;
        margin-top: -15px;
        width: 30px;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.5);
        background-repeat: no-repeat;
        background-size: 10px 15px;
        background-position: center;
        right: 0;
        background-image: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_right_sp-b97a2c55be8a3e70cdfa0cb8b2144520e23d7d7d1e11bbb80513ba22b8247a0d.png');
      }
    }
    .crn-prt.type_gallery.pattern_11 {
      ul {
        width: 50%;
        float: left;
        .crn-prt {
          width: 100%;
          float: left;
          img {
            width: 100%;
          }
        }
      }
      .gallery__group {
        &:after {
          content: '';
          display: table;
          clear: both;
        }
        &:nth-child(odd) {
          ul {
            &:nth-child(2) {
              li {
                float: left;
                width: 50%;
              }
            }
          }
        }
        &:nth-child(even) {
          ul {
            &:nth-child(1) {
              li {
                float: left;
                width: 50%;
              }
            }
          }
        }
      }
      li {
        .crn-prt.type_image {
          cursor: pointer;
          &:hover {
            opacity: 0.6;
          }
        }
      }
      .is-sp {
        li {
          .crn-prt.type_image {
            &:hover {
              opacity: 1;
            }
          }
        }
      }
      .gallery-details {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10000;
        background: rgba(0, 0, 0, 0.7);
        overflow: auto;
      }
      .gallery-details__bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 90%;
        width: calc(100% - 80px);
        height: 100%;
      }
      .gallery-details__inner {
        position: relative;
        width: 960px;
        margin: 68px auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
      .gallery-details__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        z-index: 11;
        position: relative;
        background: #ffffff;
      }
      .gallery-details__img {
        min-height: 480px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .crn-prt.type_image {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
        }
      }
      .gallery-details__details {
        padding: 20px;
      }
      .gallery-details__left {
        z-index: 100;
        cursor: pointer;
        position: absolute;
        top: 246px;
        width: 180px;
        height: 200px;
        left: 0;
        &:hover {
          opacity: 0.6;
        }
        &:after {
          position: absolute;
          content: '';
          display: table;
          top: 76px;
          width: 26px;
          height: 48px;
          left: 26px;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_left-63a57c7a2ddeccc578a03409cf0f6368fb8e27ecceebe783d33b2150f646d98c.png')
            transparent no-repeat;
          background-size: cover;
        }
      }
      .gallery-details__right {
        z-index: 100;
        cursor: pointer;
        position: absolute;
        top: 246px;
        width: 180px;
        height: 200px;
        right: 0;
        &:hover {
          opacity: 0.6;
        }
        &:after {
          position: absolute;
          content: '';
          display: table;
          top: 76px;
          width: 26px;
          height: 48px;
          right: 26px;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_right-3dde2202371c49b1929c569c3d634734ae8efb99d64117fc0c2ad32ebd6797bd.png')
            transparent no-repeat;
          background-size: cover;
        }
      }
      .gallery-details__close {
        position: absolute;
        cursor: pointer;
        top: -42px;
        right: 0;
        width: 24px;
        height: 24px;
        &:hover {
          opacity: 0.6;
        }
        span {
          display: block;
          position: absolute;
          top: 11px;
          left: -2px;
          width: 29px;
          height: 3px;
          background: #fff;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          &:nth-child(2) {
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
      }
    }
    .crn-prt.type_gallery.pattern_12 {
      li {
        display: inline-block;
        vertical-align: top;
        .crn-prt.type_image {
          cursor: pointer;
          &:hover {
            opacity: 0.6;
          }
        }
      }
      .is-sp {
        li {
          .crn-prt.type_image {
            &:hover {
              opacity: 1;
            }
          }
        }
      }
      .gallery-details {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10000;
        background: rgba(0, 0, 0, 0.7);
        overflow: auto;
      }
      .gallery-details__bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 90%;
        width: calc(100% - 80px);
        height: 100%;
      }
      .gallery-details__inner {
        position: relative;
        width: 960px;
        margin: 68px auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
      .gallery-details__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        z-index: 11;
        position: relative;
        background: #ffffff;
      }
      .gallery-details__img {
        min-height: 480px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .crn-prt.type_image {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
        }
      }
      .gallery-details__details {
        padding: 20px;
      }
      .gallery-details__left {
        z-index: 100;
        cursor: pointer;
        position: absolute;
        top: 246px;
        width: 180px;
        height: 200px;
        left: 0;
        &:hover {
          opacity: 0.6;
        }
        &:after {
          position: absolute;
          content: '';
          display: table;
          top: 76px;
          width: 26px;
          height: 48px;
          left: 26px;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_left-63a57c7a2ddeccc578a03409cf0f6368fb8e27ecceebe783d33b2150f646d98c.png')
            transparent no-repeat;
          background-size: cover;
        }
      }
      .gallery-details__right {
        z-index: 100;
        cursor: pointer;
        position: absolute;
        top: 246px;
        width: 180px;
        height: 200px;
        right: 0;
        &:hover {
          opacity: 0.6;
        }
        &:after {
          position: absolute;
          content: '';
          display: table;
          top: 76px;
          width: 26px;
          height: 48px;
          right: 26px;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_right-3dde2202371c49b1929c569c3d634734ae8efb99d64117fc0c2ad32ebd6797bd.png')
            transparent no-repeat;
          background-size: cover;
        }
      }
      .gallery-details__close {
        position: absolute;
        cursor: pointer;
        top: -42px;
        right: 0;
        width: 24px;
        height: 24px;
        &:hover {
          opacity: 0.6;
        }
        span {
          display: block;
          position: absolute;
          top: 11px;
          left: -2px;
          width: 29px;
          height: 3px;
          background: #fff;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          &:nth-child(2) {
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
      }
    }
    .crn-prt.type_gallery.pattern_22 {
      li {
        .crn-prt.type_image {
          cursor: pointer;
          &:hover {
            opacity: 0.6;
          }
        }
        width: 84px;
        height: 63px;
        display: inline-block;
        margin-right: 4px;
        div {
          width: 100%;
        }
        img {
          width: 100%;
        }
      }
      .is-sp {
        li {
          .crn-prt.type_image {
            &:hover {
              opacity: 1;
            }
          }
        }
      }
      .gallery-details {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10000;
        background: rgba(0, 0, 0, 0.7);
        overflow: auto;
      }
      .gallery-details__bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 90%;
        width: calc(100% - 80px);
        height: 100%;
      }
      .gallery-details__inner {
        position: relative;
        width: 960px;
        margin: 68px auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
      .gallery-details__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        z-index: 11;
        position: relative;
        background: #ffffff;
      }
      .gallery-details__img {
        min-height: 480px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .crn-prt.type_image {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
        }
      }
      .gallery-details__details {
        padding: 20px;
      }
      .gallery-details__left {
        z-index: 100;
        cursor: pointer;
        position: absolute;
        top: 246px;
        width: 180px;
        height: 200px;
        left: 0;
        &:hover {
          opacity: 0.6;
        }
        &:after {
          position: absolute;
          content: '';
          display: table;
          top: 76px;
          width: 26px;
          height: 48px;
          left: 26px;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_left-63a57c7a2ddeccc578a03409cf0f6368fb8e27ecceebe783d33b2150f646d98c.png')
            transparent no-repeat;
          background-size: cover;
        }
      }
      .gallery-details__right {
        z-index: 100;
        cursor: pointer;
        position: absolute;
        top: 246px;
        width: 180px;
        height: 200px;
        right: 0;
        &:hover {
          opacity: 0.6;
        }
        &:after {
          position: absolute;
          content: '';
          display: table;
          top: 76px;
          width: 26px;
          height: 48px;
          right: 26px;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_right-3dde2202371c49b1929c569c3d634734ae8efb99d64117fc0c2ad32ebd6797bd.png')
            transparent no-repeat;
          background-size: cover;
        }
      }
      .gallery-details__close {
        position: absolute;
        cursor: pointer;
        top: -42px;
        right: 0;
        width: 24px;
        height: 24px;
        &:hover {
          opacity: 0.6;
        }
        span {
          display: block;
          position: absolute;
          top: 11px;
          left: -2px;
          width: 29px;
          height: 3px;
          background: #fff;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          &:nth-child(2) {
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
      }
      ul {
        width: auto;
        overflow-x: auto;
        white-space: nowrap;
      }
    }
    .crn-prt.type_gallery {
      .gallery-details-pattern21 {
        .gallery-details__inner {
          width: 100%;
        }
        .gallery-details__bg {
          width: 100%;
        }
        .gallery-details__content {
          width: 292px;
          margin: 0 auto;
          background-color: transparent;
          margin: 0;
        }
        .gallery-details {
          _ {
            &:lang(x) {
              &::-ms-backdrop {
                margin: 0;
              }
            }
          }
        }
        .gallery-details__img {
          min-height: 1px;
          position: relative;
        }
        .gallery-details__close {
          top: -28px;
          width: 18px;
          height: 18px;
          span {
            top: 9px;
            width: 23px;
            height: 2px;
          }
        }
        .swipe-list {
          border-radius: 5px;
          overflow: hidden;
          background-color: white;
        }
        .gallery-main__image {
          position: relative;
          .type_image {
            width: 292px;
            height: 219px;
          }
        }
        .gallery-main__image__left {
          display: block;
          cursor: pointer;
          position: absolute;
          top: 50%;
          margin-top: -15px;
          width: 30px;
          height: 30px;
          background-color: rgba(0, 0, 0, 0.5);
          background-repeat: no-repeat;
          background-size: 10px 15px;
          background-position: center;
          left: 0;
          background-image: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_left_sp-f62d2752a9f13679b99833af947f018e492ed3c0b6c243f4cd956e4266295e5b.png');
        }
        .gallery-main__image__right {
          display: block;
          cursor: pointer;
          position: absolute;
          top: 50%;
          margin-top: -15px;
          width: 30px;
          height: 30px;
          background-color: rgba(0, 0, 0, 0.5);
          background-repeat: no-repeat;
          background-size: 10px 15px;
          background-position: center;
          right: 0;
          background-image: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/g_right_sp-b97a2c55be8a3e70cdfa0cb8b2144520e23d7d7d1e11bbb80513ba22b8247a0d.png');
        }
      }
    }
    .crn-prtg1 {
      margin-left: -14px;
      margin-right: -14px;
      .gallery-main {
        width: 100%;
        .crn-prt {
          width: 100%;
        }
      }
      .gallery-caption {
        padding-left: 14px;
        padding-right: 14px;
      }
      .gallery-thumbs {
        padding-left: 14px;
        padding-right: 14px;
      }
      li {
        .crn-prt {
          width: 60px;
          height: 30px;
        }
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
      li.selected {
        .crn-prt {
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 2px solid var(--color-secondary);
          }
        }
      }
    }
    .crn-prti3 {
      img {
        max-width: 100%;
      }
    }
    .crn-prtls1 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 30px;
          }
        }
      }
    }
    .crn-prtg2 {
      margin-left: -14px;
      margin-right: -14px;
      .gallery-main {
        width: 100%;
        .crn-prt {
          width: 100%;
        }
      }
      .gallery-caption {
        padding-left: 14px;
        padding-right: 14px;
      }
      .gallery-thumbs {
        padding-left: 14px;
        padding-right: 14px;
      }
      li {
        .crn-prt {
          width: 60px;
          height: 45px;
        }
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
      li.selected {
        .crn-prt {
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 2px solid var(--color-secondary);
          }
        }
      }
    }
    .crn-prti4 {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .crn-prtls2 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
    .crn-prtg3 {
      ul {
        .crn-prt {
          margin-bottom: 3px;
        }
        &:nth-child(odd) {
          padding-right: 1.5px;
        }
        &:nth-child(even) {
          padding-left: 1.5px;
        }
      }
      .gallery__group {
        &:nth-child(odd) {
          ul {
            &:nth-child(2) {
              li {
                &:nth-child(odd) {
                  padding-right: 1.5px;
                }
                &:nth-child(even) {
                  padding-left: 1.5px;
                }
              }
            }
          }
        }
        &:nth-child(even) {
          ul {
            &:nth-child(1) {
              li {
                &:nth-child(odd) {
                  padding-right: 1.5px;
                }
                &:nth-child(even) {
                  padding-left: 1.5px;
                }
              }
            }
          }
        }
      }
    }
    .crn-prt.type_jln_news_list {
      position: relative;
      &:after {
        content: ' ';
        display: table;
        clear: both;
      }
      .date {
        width: 100px;
        font-size: 12px;
        display: inline-block;
      }
      .type {
        display: inline-block;
        .news-type {
          width: 90px;
          display: inline-block;
          text-align: center;
          font-size: 12px;
        }
      }
      .title {
        display: inline-block;
        margin-left: 16px;
        font-size: 16px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .crn-prt.type_jln_news_list.pattern_1 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      .date {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100px;
        flex: 0 0 100px;
        line-height: 1.5;
        letter-spacing: 0;
      }
      .type {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 90px;
        flex: 0 0 90px;
        .news-type {
          line-height: 1.5;
          letter-spacing: 0;
        }
      }
    }
    .crn-prt.type_jln_news_list.pattern_31 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      .date {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100px;
        flex: 0 0 100px;
        line-height: 1.5;
        letter-spacing: 0;
      }
      .type {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 90px;
        flex: 0 0 90px;
        .news-type {
          line-height: 1.5;
          letter-spacing: 0;
        }
      }
      .title {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .crn-prt.type_jln_news_list.pattern_21 {
      .date {
        width: 90px;
        margin-bottom: 10px;
      }
      .type {
        margin-bottom: 10px;
      }
      .title {
        font-size: 14px;
        display: block;
        margin-left: 0;
        margin-right: 20px;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .crn-prtnews_title {
      .title {
        color: #2e518b;
      }
      .news-type.type--1 {
        background-color: #ece6da;
      }
      .news-type.type--2 {
        background-color: #dae2e0;
      }
    }
    .crn-prtls3 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
    .crn-prt_ft11 {
      padding: 0 12px 0 0;
    }
    .crn-prti6 {
      img {
        max-width: 100%;
      }
    }
    .crn-prt.type_page_top {
      position: fixed;
      bottom: 20px;
      right: 20px;
      cursor: pointer;
      z-index: 2000;
      &:hover {
        opacity: 0.6;
      }
    }
    .crn-prt.type_page_top.pattern_31 {
      background-color: #ca9a34;
      &:hover {
        opacity: 1;
        background-color: #ddb768;
      }
    }
    .crn-prt.type_page_top.tripai-cntrl-hidden {
      display: none;
    }
    .crn-prti7 {
      img {
        max-width: 100%;
      }
    }
    .crn-prtls5 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 14px;
            border-bottom: 1px solid #efefef;
            padding-bottom: 14px;
          }
        }
      }
    }
    a.crn-prt_ft13 {
      display: inline-block;
    }
    .crn-prti8 {
      img {
        width: 100%;
      }
    }
    .crn-prtls6 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 14px;
            border-bottom: 1px solid #efefef;
            padding-bottom: 14px;
          }
        }
      }
    }
    .crn-prti9 {
      img {
        width: 100%;
      }
    }
    .crn-prthl6b {
      a {
        display: inline-block;
      }
    }
    .crn-prtls9b {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 14px;
            border-bottom: 1px solid #efefef;
            padding-bottom: 14px;
          }
        }
      }
    }
    .crn-prtls7 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 14px;
            border-bottom: 1px solid #efefef;
            padding-bottom: 14px;
          }
        }
      }
    }
    a.crn-prt_ft26 {
      display: inline-block;
      margin-left: 10px;
    }
    .crn-prtls8 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 14px;
            border-bottom: 1px solid #efefef;
            padding-bottom: 14px;
          }
        }
      }
    }
    .crn-prthl5 {
      a {
        display: inline-block;
      }
    }
    .crn-prthl4b {
      a {
        display: inline-block;
      }
    }
    .crn-prthl6d {
      a {
        display: inline-block;
      }
    }
    .crn-prtls9 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 14px;
            border-bottom: 1px solid #efefef;
            padding-bottom: 14px;
          }
        }
      }
    }
    .crn-prt_fp_text_image_tile_image_top_1 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }
    a.crn-prt_ft28 {
      display: inline-block;
    }
    .crn-prtt10 {
      .text--more {
        color: #2e518b;
      }
    }
    .crn-prthl3b {
      a {
        display: inline-block;
      }
    }
    .crn-prthl6e {
      a {
        display: inline-block;
      }
    }
    .crn-prtbtn1 {
      background-color: var(--color-secondary);
      .btn--body {
        line-height: 20px;
        padding-left: 24px;
        padding-right: 24px;
      }
    }
    .crn-prtbtn1.pattern_2 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
      }
    }
    .crn-prtbtn1.pattern_21 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
      }
    }
    .crn-prthl6g {
      a {
        display: inline-block;
      }
    }
    .crn-prt.type_box {
      overflow: hidden;
    }
    .crn-prt.type_box.pattern_2 {
      border-style: solid;
    }
    .crn-prt.type_box.pattern_3 {
      border-style: solid;
    }
    .crn-prt_hd1 {
      > .content {
        padding: 10px;
      }
    }
    .crn-prthl6h {
      a {
        display: inline-block;
      }
    }
    .crn-prtg2b {
      margin-left: -14px;
      margin-right: -14px;
      .gallery-main {
        width: 100%;
        .crn-prt {
          width: 100%;
        }
      }
      .gallery-caption {
        padding-left: 14px;
        padding-right: 14px;
      }
      .gallery-thumbs {
        padding-left: 14px;
        padding-right: 14px;
      }
      li {
        .crn-prt {
          width: 60px;
          height: 45px;
        }
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
      li.selected {
        .crn-prt {
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 2px solid var(--color-secondary);
          }
        }
      }
    }
    .crn-prtbtn3 {
      background-color: var(--color-secondary);
      .btn--body {
        line-height: 40px;
        padding-left: 24px;
        padding-right: 24px;
      }
    }
    .crn-prtbtn3.pattern_2 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
      }
    }
    .crn-prtbtn3.pattern_21 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
      }
    }
    .crn-prt_fp_image_1 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }
    a.crn-prt_hd2 {
      display: inline-block;
    }
    .crn-prt_fp_text_bg_color_1 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }
    .crn-prtg1b {
      margin-left: -14px;
      margin-right: -14px;
      .gallery-main {
        width: 100%;
        .crn-prt {
          width: 100%;
        }
      }
      .gallery-caption {
        padding-left: 14px;
        padding-right: 14px;
      }
      .gallery-thumbs {
        padding-left: 14px;
        padding-right: 14px;
      }
      li {
        .crn-prt {
          width: 60px;
          height: 30px;
        }
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
      li.selected {
        .crn-prt {
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 2px solid var(--color-secondary);
          }
        }
      }
    }
    .crn-prt_hd3 {
      li {
        border-bottom: 1px solid #efefef;
        &:first-child {
          border-top: 1px solid #efefef;
        }
      }
    }
    a.crn-prttl {
      display: inline;
    }
    .crn-prttl {
      .text--more {
        color: #2e518b;
      }
    }
    a.crn-prtal1_1 {
      display: inline-block;
    }
    .crn-prtbtn5 {
      background-color: var(--color-secondary);
      .btn--body {
        color: #ffffff;
        line-height: 40px;
        padding-left: 50px;
        padding-right: 50px;
      }
    }
    .crn-prtbtn5.pattern_2 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
      }
    }
    .crn-prtbtn5.pattern_21 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
      }
    }
    .crn-prt.type_jln_contact {
      .contact-row {
        margin-bottom: 20px;
        .contact-left {
          margin-bottom: 5px;
          min-width: 230px;
          margin-right: -30px;
          padding-right: 20px;
        }
        .contact-right {
          font-size: 16px;
          margin-left: 30px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      .contact-row.contact-chat {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        .contact-left {
          p {
            a {
              font-size: 18px;
              display: inline;
            }
          }
        }
        .contact-right {
          width: 100%;
          p {
            margin-bottom: 10px;
            padding-top: 2px;
          }
          ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            li {
              font-size: 14px;
              margin-right: 25px;
              vertical-align: middle;
              .svg-icon {
                font-size: 24px;
                padding: 1px;
                fill: #333;
                margin-right: 8px;
                vertical-align: -0.2em;
              }
              .svg-icon__bg {
                fill: #fff;
                opacity: 1;
              }
            }
          }
        }
      }
      .contact-row.contact-tel {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        .contact-left {
          margin-bottom: 0;
          p {
            font-size: 18px;
            font-weight: bold;
          }
        }
        .contact-right {
          p {
            padding-top: 5px;
          }
        }
      }
      .contact-row.contact-email {
        .contact-left {
          width: 100%;
          padding-right: 0;
          p {
            word-wrap: break-word;
            word-break: break-word;
            a {
              font-size: 16px;
            }
          }
        }
      }
    }
    .crn-prt.type_jln_contact.pattern_2 {
      .contact-row.contact-chat {
        .contact-right {
          ul {
            li {
              margin-right: 10px;
            }
            li.chat-list1 {
              &:before {
                margin-right: 5px;
              }
            }
            li.chat-list2 {
              &:before {
                margin-right: 5px;
              }
            }
            li.chat-list3 {
              &:before {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
    .crn-prt.type_jln_contact.pattern_21 {
      .contact-row {
        margin-bottom: 14px;
        .contact-right {
          font-size: 11px;
          margin-left: 22px;
        }
        .contact-left {
          font-weight: bold;
          margin-right: 0;
          padding-right: 0;
          .svg-icon {
            vertical-align: top;
            margin-top: 3px;
          }
        }
      }
      .contact-row.contact-desc {
        min-height: 21px;
      }
      .contact-row.contact-chat {
        display: block;
        .contact-left {
          p {
            a {
              font-size: 14px;
              display: inline;
            }
          }
        }
        .contact-right {
          width: auto;
          ul {
            display: block;
            li {
              margin-bottom: 4px;
              font-size: 10px;
              line-height: 24px;
              .svg-icon {
                font-size: 22px;
              }
            }
          }
        }
      }
      .contact-row.contact-tel {
        display: block;
        word-break: break-all;
        .contact-left {
          p {
            font-size: 14px;
            position: relative;
            padding-left: 21px;
          }
          .svg-icon {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        a {
          display: inline;
        }
      }
      .contact-row.contact-email {
        margin-bottom: 0;
        .contact-left {
          width: 100%;
          padding-right: 0;
          p {
            a {
              font-size: 12px;
            }
          }
        }
      }
    }
    .crn-prt_t4_content {
      .contact-left {
        p {
          a {
            color: #2e518b;
          }
        }
      }
    }
    a.crn-prtal2_1 {
      display: inline-block;
    }
    .crn-prt_fp_text_image_list_left_1 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }
    a.crn-prt_hd21 {
      display: inline-block;
    }
    .crn-prtbtn6 {
      background-color: var(--color-secondary);
      .btn--body {
        line-height: 20px;
        padding-left: 50px;
        padding-right: 50px;
      }
    }
    .crn-prtbtn6.pattern_2 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
      }
    }
    .crn-prtbtn6.pattern_21 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
      }
    }
    a.crn-prtal3_1 {
      display: inline-block;
    }
    .crn-prt_hd11 {
      img {
        max-width: 100%;
        max-height: 34px;
      }
    }
    .crn-prtbtn7 {
      background-color: var(--color-secondary);
      .btn--body {
        line-height: 20px;
        padding-left: 24px;
        padding-right: 24px;
      }
    }
    .crn-prtbtn7.pattern_2 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
      }
    }
    .crn-prtbtn7.pattern_21 {
      .btn--body {
        box-shadow: 0 0 0 2px var(--color-secondary);
      }
    }
    .crn-prt.type_label {
      display: inline-block;
      text-align: center;
    }
    .crn-prt.type_label.pattern_1 {
      border-style: solid;
      border-width: 1px;
      font-size: 10px;
      min-width: 48px;
      height: 20px;
      line-height: 18px;
      padding: 0 3px;
    }
    .crn-prt_fp_separator_solid {
      border-bottom: 1px solid #efefef;
    }
    a.crn-prt_hd6 {
      display: inline-block;
    }
    .crn-prtbx1 {
      border-width: 2px;
      border-color: #efefef;
      > .content {
        padding: 0px;
      }
    }
    .crn-prtspr1 {
      border-bottom: 1px solid #efefef;
    }
    .crn-prt.type_jln_language.pattern_1 {
      position: relative;
      border-left-width: 1px;
      border-left-style: solid;
      .lang-bg {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 500;
      }
      .lang-label {
        padding: 0 30px;
        width: 137px;
        cursor: pointer;
        vertical-align: bottom;
        &:hover {
          text-decoration: underline;
        }
      }
      .svg-icon {
        font-size: 14px;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -7px;
      }
      .lang-list {
        width: 100%;
        position: absolute;
        margin-top: 5px;
        background-color: #efefef;
        padding: 20px;
        z-index: 501;
        li {
          &:not(:last-child) {
            padding-bottom: 20px;
          }
        }
        a {
          color: #000000;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .crn-prt.type_jln_language.pattern_21 {
      ul {
        margin-left: -5px;
        margin-right: -5px;
        li {
          display: inline-block;
          vertical-align: top;
          width: 33.33%;
          padding: 5px;
          a {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            height: 44px;
            border: 1px solid var(--color-background-1);
            text-align: center;
          }
        }
        li.current {
          a {
            border-width: 2px;
            color: #333333;
          }
        }
      }
    }
    .crn-prt.type_jln_language.pattern_32 {
      ul {
        margin-left: -5px;
        margin-right: -5px;
        li {
          display: inline-block;
          vertical-align: top;
          width: 33.33%;
          padding: 5px;
          a {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            height: 44px;
            border: 1px solid var(--color-background-1);
            text-align: center;
            border: 1px solid var(--color-background-1);
          }
        }
        li.current {
          a {
            border-width: 2px;
            color: #333333;
            color: #ffffff;
            border-width: 2px;
            border-color: #ca9a34 !important;
          }
        }
      }
    }
    .crn-prt_hd7 {
      li.current {
        a {
          border-color: var(--color-secondary) !important;
        }
      }
    }
    .crn-prtbx2 {
      > .content {
        padding: 10px;
      }
    }
    .crn-prt_t1 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }
      }
    }
    .crn-prt.type_jln_news_details {
      margin-bottom: 16px;
      .jln-news-details__type {
        display: inline-block;
        margin-bottom: 16px;
        .news-type {
          width: 90px;
          display: inline-block;
          text-align: center;
          font-size: 12px;
        }
      }
      .jln-news-details__title {
        font-weight: bold;
        font-size: 24px;
        line-height: 1.5;
        letter-spacing: 0;
      }
      .jln-news-details__contents {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
      }
      .jln-news-details__sns {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        line-height: 1.5;
        > div {
          padding-left: 10px;
        }
      }
      .jln-news-details__date {
        text-align: right;
      }
      .jln-news-details__info {
        > div {
          display: inline-block;
        }
      }
    }
    .crn-prt.type_jln_news_details.pattern_21 {
      .jln-news-details__type {
        margin-bottom: 10px;
        font-size: 12px;
      }
      .jln-news-details__title {
        font-size: 16px;
        margin-bottom: 10px;
        letter-spacing: -0.05em;
      }
      .jln-news-details__date {
        text-align: left;
        display: inline-block;
        margin-right: 6px;
        font-size: 11px;
        color: #767676;
      }
      .jln-news-details__contents {
        display: block;
        padding-bottom: 14px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
        font-size: 10px;
      }
      .jln-news-details__sns {
        margin-top: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
      .jln-news-details__info {
        > div {
          display: block;
        }
      }
    }
    .crn-prt.type_jln_news_details.pattern_32 {
      .jln-news-details__type {
        margin-bottom: 10px;
        font-size: 12px;
      }
      .jln-news-details__title {
        font-size: 16px;
        margin-bottom: 10px;
        letter-spacing: -0.05em;
      }
      .jln-news-details__date {
        text-align: left;
        display: inline-block;
        margin-right: 6px;
        font-size: 11px;
        color: #767676;
        color: #ffffff;
      }
      .jln-news-details__contents {
        display: block;
        padding-bottom: 14px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
        font-size: 10px;
      }
      .jln-news-details__sns {
        margin-top: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
      .jln-news-details__info {
        > div {
          display: block;
        }
      }
    }
    .crn-prt_nd2 {
      .news-type.type--1 {
        background-color: #ece6da;
      }
      .news-type.type--2 {
        background-color: #dae2e0;
      }
    }
    .crn-prt.type_jln_reviews {
      .jln-reviews__title {
        .jln-stars {
          margin-bottom: 16px;
          padding-right: 24px;
        }
      }
      .jln-reviews__title.with-border {
        margin-bottom: 16px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        .jln-stars {
          margin-bottom: 10px;
        }
        .jln-reviews__texts {
          margin-bottom: 10px;
        }
      }
      .jln-reviews__texts {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 16px;
      }
      .jln-reviews__txt1 {
        padding-right: 24px;
      }
      .jln-reviews__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }
      table {
        &:not(:last-child) {
          margin-right: 30px;
        }
      }
      th {
        font-size: 12px;
        text-align: left;
        vertical-align: top;
        &:first-child {
          padding-right: 14px;
        }
      }
      td {
        font-size: 12px;
        text-align: left;
        vertical-align: top;
        &:first-child {
          padding-right: 14px;
        }
      }
      tr {
        &:not(:last-child) {
          th {
            &:first-child {
              padding-bottom: 16px;
            }
          }
          td {
            &:first-child {
              padding-bottom: 16px;
            }
          }
        }
      }
    }
    .crn-prt.type_jln_reviews.pattern_1 {
      .jln-reviews__title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }
    }
    .crn-prt.type_jln_reviews.pattern_21 {
      .jln-reviews__title {
        .jln-stars {
          margin-top: 16px;
          margin-bottom: 6px;
          padding-right: 24px;
        }
      }
      .jln-reviews__texts {
        display: block;
        font-size: 10px;
      }
      th {
        font-weight: bold;
      }
    }
    .crn-prt_fp_main_image_1 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }
    .crn-prtspr2 {
      border-bottom: 1px solid #efefef;
    }
    .crn-prt.type_jln_search {
      .calendar--false {
        display: none;
      }
      .calendar--true {
        display: block;
      }
      .calendar-staylength {
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding: 0 7px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        .stayDates--undecided {
          color: #999999;
          display: block;
        }
        .stayDates--arrow {
          margin: 0 5px;
        }
        .stayDates--length {
          margin-left: 3px;
        }
      }
      .calendar-staylength.is-pc {
        letter-spacing: 0;
      }
      .calendar--outsideClickHandler {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 450;
      }
      .search-wrap {
        position: relative;
      }
      .search-label {
        font-size: 12px;
        padding-left: 3px;
      }
      .search-area-label {
        font-weight: bold;
        font-size: 16px;
      }
      .search-area-label.is-sp {
        font-size: 14px;
        margin-bottom: 10px;
      }
      .staylength-wrap {
        position: relative;
        background-color: #fff;
        color: #000000;
        border: 1px solid #333;
        width: 100%;
      }
      .select-wrap {
        position: relative;
        background-color: #fff;
        color: #000000;
        border: 1px solid #333;
        width: 100%;
        select {
          cursor: pointer;
          font-size: 14px;
          background-color: transparent;
          border-style: none;
          color: inherit;
          width: 100%;
          padding: 7px;
          height: 40px;
        }
        .number--inEditor {
          cursor: default;
          font-size: 14px;
          background-color: transparent;
          border-style: none;
          color: inherit;
          width: 100%;
          padding: 7px;
          height: 40px;
          line-height: 28px;
          display: block;
        }
        &:before {
          content: '';
          display: block;
          z-index: 1;
          position: absolute;
          width: 16px;
          height: 9px;
          right: 15px;
          top: 15px;
          pointer-events: none;
          background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_down-5bdd393dafe6dda3899a01248184114dafb414a35b44223627d77eb99f22518a.png')
            transparent no-repeat;
          background-size: cover;
        }
      }
      .search-calendar.is-pc {
        line-height: 1.5;
        letter-spacing: 0;
      }
      .search-number.is-pc {
        line-height: 1.5;
        letter-spacing: 0;
      }
      .search-calendar.is-sp {
        .staylength-wrap {
          color: #333333;
        }
      }
      .search-number.is-sp {
        .select-wrap {
          color: #333333;
        }
      }
      .calendar-open {
        display: block;
        position: absolute;
        z-index: 500;
        background-color: #fff;
        border: 1px solid #cccccc;
        padding: 30px 20px 15px;
        width: 690px;
        box-sizing: border-box;
        overflow: hidden;
        top: 59px;
        .tbd {
          text-align: right;
          margin-top: 6px;
          padding-right: 10px;
          font-size: 14px;
          a {
            display: inline-block;
          }
          a.tbd__close {
            display: none;
          }
        }
      }
      .calendar-open.is-pc {
        letter-spacing: 0;
        .tbd {
          a {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .calendar-open.is-sp {
        width: 100%;
        left: 0;
        top: 60px;
        border-color: #333333;
        padding: 0;
        .DayPicker__week-header {
          font-weight: bold;
        }
        .CalendarDay.CalendarDay--selected-start {
          button {
            font-weight: bold;
          }
        }
        .CalendarDay.CalendarDay--selected-end {
          button {
            font-weight: bold;
          }
        }
        .CalendarDay.CalendarDay--selected-span {
          button {
            font-weight: bold;
          }
        }
        .calendar-open__inner {
          width: 272px;
          margin: 0 auto;
          overflow: hidden;
          padding: 18px 0 0;
        }
        .tbd {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          padding: 12px;
          margin-top: 0;
          border-top: 1px solid #efefef;
          a {
            font-size: 12px;
            display: block;
          }
          a.tbd__close {
            color: #333333;
            display: block;
            position: relative;
            padding-left: 14px;
            span {
              position: absolute;
              display: block;
              top: 50%;
              left: 0;
              margin-top: -6px;
              width: 1px;
              height: 12px;
              background: #333;
              &:nth-child(1) {
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
              }
              &:nth-child(2) {
                -webkit-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
              }
            }
          }
        }
        .DayPickerNavigation--horizontal {
          .DayPickerNavigation__prev {
            left: 0px;
          }
          .DayPickerNavigation__next {
            right: 44px;
          }
        }
      }
      .CalendarDay {
        padding: 0;
        box-sizing: border-box;
        cursor: pointer;
        height: 40px !important;
        color: #333333;
        &:nth-child(7n + 6) {
          color: #03c;
        }
        &:nth-child(7n) {
          color: #c03;
        }
        .CalendarDay--outside {
          border: 0;
          cursor: default;
          &:active {
            background: #fff;
          }
        }
      }
      .CalendarDay.CalendarDay--hovered {
        color: #fff !important;
        opacity: 1 !important;
      }
      .CalendarDay.CalendarDay--selected-span {
        color: #fff !important;
      }
      .CalendarDay.CalendarDay--selected-span.CalendarDay--hovered {
        color: #fff !important;
      }
      .CalendarDay.CalendarDay--hovered-span {
        color: #fff !important;
      }
      .CalendarDay.CalendarDay--selected-start {
        color: #fff !important;
        font-weight: bold;
        opacity: 1;
      }
      .CalendarDay.CalendarDay--selected-end {
        color: #fff !important;
        font-weight: bold;
        opacity: 1;
      }
      .CalendarDay.CalendarDay--selected {
        color: #fff !important;
        font-weight: bold;
        opacity: 1;
      }
      .CalendarDay.CalendarDay--blocked-out-of-range {
        color: #ccc !important;
        background: #fff;
        cursor: default;
        .CalendarDay__button {
          cursor: default;
        }
        &:active {
          background: #fff;
        }
      }
      .CalendarDay__button {
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        position: relative;
        height: 100%;
        width: 100%;
        text-align: center;
        background: none;
        border: 0;
        margin: 0;
        padding: 0;
        font-size: inherit;
        color: inherit;
        line-height: normal;
        cursor: pointer;
        box-sizing: border-box;
        outline: none;
        &:active {
          outline: 0;
        }
      }
      .CalendarMonth {
        text-align: center;
        vertical-align: top;
        padding-right: 27px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        table {
          border-collapse: collapse;
          border-spacing: 0;
          caption-caption-side: initial;
        }
      }
      .CalendarMonth--horizontal {
        &:first-of-type {
          position: absolute;
          z-index: -1;
          opacity: 0;
          pointer-events: none;
        }
        display: inline-block;
        min-height: 100%;
        .CalendarMonth__caption {
          padding: 0 0 35px;
        }
      }
      .CalendarMonth__caption {
        color: #555;
        margin-top: 7px;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
      }
      .CalendarMonthGrid {
        background: #fff;
        z-index: 0;
        text-align: left;
      }
      .CalendarMonthGrid--animating {
        -webkit-transition-duration: 0.001s;
        transition-duration: 0.001s;
        z-index: 1;
      }
      .transition-container {
        position: relative;
        overflow: hidden;
      }
      .CalendarMonthGrid--horizontal {
        position: absolute;
      }
      .CalendarDay.CalendarDay--highlighted-calendar {
        color: #c03;
      }
      .DayPicker {
        background: #fff;
        position: relative;
        text-align: left;
        left: 2px;
      }
      .DayPicker--horizontal {
        background: #fff;
      }
      .DayPicker__focus-region {
        outline: none;
      }
      .DayPicker__week-headers {
        position: relative;
      }
      .DayPicker__week-header {
        position: absolute;
        top: 42px;
        z-index: 2;
        text-align: left;
        ul {
          display: table;
          list-style: none;
        }
        li {
          display: table-cell;
          text-align: center;
          font-size: 12px;
          color: #333333;
          &:nth-child(7n + 6) {
            color: #03c;
          }
          &:nth-child(7n) {
            color: #c03;
          }
        }
      }
      .calstart-false {
        .DayPickerNavigation__prev {
          display: none;
        }
      }
      .calend-false {
        .DayPickerNavigation__next {
          display: none;
        }
      }
      .DayPickerNavigation__prev {
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        background: #fff;
        cursor: pointer;
        border: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        outline: none;
        z-index: 2;
        position: absolute;
        width: 30px;
        height: 30px;
        top: 2px;
        svg {
          display: none;
        }
      }
      .DayPickerNavigation__next {
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        background: #fff;
        cursor: pointer;
        border: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        outline: none;
        z-index: 2;
        position: absolute;
        width: 30px;
        height: 30px;
        top: 2px;
        svg {
          display: none;
        }
      }
      .DayPickerNavigation--horizontal {
        position: relative;
        .DayPickerNavigation__prev {
          left: 11px;
          &:before {
            content: '';
            width: 8px;
            height: 8px;
            border: 0px;
            border-top: solid 1px #222;
            border-right: solid 1px #222;
            -webkit-transform: rotate(225deg);
            -ms-transform: rotate(225deg);
            transform: rotate(225deg);
            position: absolute;
            top: 50%;
            left: 10px;
            margin-top: -4px;
          }
        }
        .DayPickerNavigation__next {
          right: 55px;
          &:before {
            content: '';
            width: 8px;
            height: 8px;
            border: 0px;
            border-top: solid 1px #222;
            border-right: solid 1px #222;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: 50%;
            right: 10px;
            margin-top: -4px;
          }
        }
      }
    }
    .crn-prt.type_jln_search.pattern_1 {
      padding: 40px 0 46px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;
      .search-wrap {
        width: 136px;
      }
      .search-wrap.search-calendar {
        width: 336px;
      }
      .calendar-open {
        left: 0;
      }
      .search-number {
        margin-right: 10px;
        .select-wrap {
          border-left: none;
        }
      }
    }
    .crn-prt.type_jln_search.pattern_2 {
      .search-wrap {
        margin-bottom: 0px;
      }
    }
    .crn-prt_hd24 {
      padding: 10px 0 18px;
      .CalendarDay.CalendarDay--hovered-span {
        background: #d7caac;
      }
      .CalendarDay.CalendarDay--selected-span {
        background: #d7caac;
      }
      .CalendarDay.CalendarDay--blocked-calendar {
        background: #d7caac;
      }
      .calendar-staylength.is-pc.is-panel--true {
        border-bottom-color: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--hovered {
        background: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--selected {
        background: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--selected-start {
        background: var(--color-secondary);
      }
      .CalendarDay.CalendarDay--selected-end {
        background: var(--color-secondary);
      }
      .tbd {
        a.tbd__none {
          color: #2e518b;
        }
      }
      .staylength-wrap {
        text-align: center;
      }
      .select-wrap {
        text-align-last: center;
      }
    }
    .crn-prtt1 {
      .text--more {
        color: #2e518b;
      }
    }
    .crn-prtbx3 {
      border-width: 2px;
      border-color: var(--color-primary);
      > .content {
        padding: 14px 14px 18px;
      }
      > .title {
        > .crn-prt {
          padding: 14px 10px;
          background-color: var(--color-primary);
        }
      }
    }
    .crn-prt_t2 {
      img {
        width: 220px;
      }
    }
    .crn-prt_nd3 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
    .crn-prtbx4 {
      > .content {
        padding: 12px;
      }
    }
    .crn-prtlnk_list2 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 14px;
          }
        }
      }
    }
    .crn-prthl6f2 {
      a {
        display: inline-block;
        width: 100%;
      }
    }
    .crn-prt_nd4 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }
    .crn-prt.type_jln_review_title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      .jln-review-title__title {
        font-size: 18px;
        font-weight: bold;
        margin-right: 20px;
      }
      .jln-review-title__sort-label {
        margin-right: 8px;
      }
      .jln-review-title__sort {
        > div {
          position: relative;
          background-color: #fff;
          border: 1px solid #333;
          min-width: 136px;
          .sort-select {
            cursor: pointer;
            font-size: 14px;
            background-color: transparent;
            border-style: none;
            color: inherit;
            width: 100%;
            padding: 7px;
            padding-right: 40px;
            height: 40px;
            line-height: 26px;
          }
          div.sort-select {
            cursor: auto;
          }
          &:before {
            content: '';
            display: block;
            z-index: 1;
            position: absolute;
            width: 16px;
            height: 9px;
            right: 15px;
            top: 15px;
            pointer-events: none;
            background: url('https://cdn.r-corona.jp/prd.jln.r-corona.jp/assets/themes/css/icons/arrow_down-5bdd393dafe6dda3899a01248184114dafb414a35b44223627d77eb99f22518a.png')
              transparent no-repeat;
            background-size: cover;
          }
        }
      }
    }
    .crn-prt.type_jln_review_title.pattern_31 {
      .jln-review-title__sort {
        > div {
          color: #000;
        }
      }
    }
    .crn-prt.type_jln_review_title.pattern_21 {
      display: block;
      text-align: left;
      position: relative;
      .jln-review-title__title {
        margin-bottom: 14px;
      }
      .jln-review-title__normal {
        font-weight: normal;
        font-size: 12px;
      }
      .jln-review-title__sort-label {
        position: absolute;
        width: 60px;
        top: 52px;
      }
      .jln-review-title__sort {
        padding-left: 60px;
        box-sizing: border-box;
        width: 100%;
      }
    }
    .crn-prtbx5 {
      border-width: 2px;
      border-color: var(--color-primary);
      > .content {
        padding: 14px;
      }
      > .title {
        > .crn-prt {
          padding: 14px 10px;
          background-color: var(--color-primary);
        }
      }
    }
    .crn-prt_s3 {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 30px;
          }
        }
      }
    }
    .crn-prt_fp_table_left_bdr {
      border-width: 1px;
      border-color: #efefef;
      > .content {
        padding: 0;
      }
    }
    .crn-prthl6e2 {
      a {
        display: inline-block;
        width: 100%;
      }
    }
  }
}
