/* You can add global styles to this file, and also import other style files */
@use '~@angular/material' as mat;
@import 'theme';
@import 'external';
@import 'material';
@import 'normalize';
@import 'pw';
@import 'structure';

@import 'gloria_g';

@import '../../node_modules/swiper/swiper-bundle.css';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');

// material 값 대신 폰트 일괄 변경

html {
  font: 400 14px / 20px Roboto, Noto Sans JP, Noto Sans KR, sans-serif;
  scroll-padding-top: $header-height;
  @media (max-width: 768px) {
    scroll-padding-top: $header-mobile-height;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

// router link 들어간 요소 모두 손가락 커서
tr,
li,
div,
span,
label,
button {
  &[ng-reflect-router-link] {
    cursor: pointer;
  }
}

* {
  user-select: none;
  -webkit-user-select: none;
  input,
  textarea {
    user-select: auto;
    -webkit-user-select: auto;
  }
}

.fab-btn-area {
  z-index: 100;
}

// 어두운 배경 위에 클릭 가능한 요소 가이드를 밝게 표시
.highlight-link {
  .fab-btn-area {
    z-index: 101;
  }
  .mat-sidenav-container,
  .mat-sidenav,
  .mat-drawwer-content,
  .mat-drawer.mat-drawer-side,
  .mat-sidenav-content,
  .mat-tab-link-container {
    z-index: initial;
  }
  .menu-tabbar {
    z-index: 101;
  }
  .need-guide {
    position: relative;
    z-index: 102;
    border: 1px solid red !important;
    box-sizing: border-box;
    box-shadow: 0 0 0 2px red !important;
    filter: brightness(1.2);
  }
}

// 팝업 모달 기본 패딩 제거
.modal-no-padding {
  mat-dialog-container {
    padding: 0;
  }
}

.popup {
  max-width: 480px !important;
  mat-dialog-container {
    max-width: 480px;
  }
}

.mobile-popup-with-header {
  width: 100vw !important;
  height: calc(100vh - 50px);
  position: fixed;
  top: 50px;
  left: 0;
  bottom: 0;
  background-color: #fff;
}

.mobile-popup {
  width: 100vw !important;
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
}

// mat-drawer-container 배경 회색 제거
.mat-drawer-container {
  background-color: transparent;
}

a {
  text-decoration: none;
}

.font-display-4 {
  font-size: 60px;
}

.font-title {
  font-size: 36px;
}

.font-subheading-2 {
  font-size: 30px;
}

.font-headline {
  font-size: 24px;
}

.font-subheading-1 {
  font-size: 22px;
}

.font-body {
  font-size: 16px;
}

.font-caption {
  font-size: 14px;
}

.font-input {
  font-size: 16px;
}

.font-button {
  font-size: 20px;
}

.font-ruby {
  font-size: 0.8em;
  margin-bottom: 2px !important;
}

.mobile {
  .font-display-4 {
    font-size: 20px;
  }

  .font-title {
    font-size: 14px;
  }

  .font-subheading-2 {
    font-size: 12px;
  }

  .font-headline {
    font-size: 11px;
  }

  .font-subheading-1 {
    font-size: 12px;
  }

  .font-body {
    font-size: 12px;
  }

  .font-caption {
    font-size: 11px;
  }

  .font-input {
    font-size: 12px;
  }

  .font-button {
    font-size: 12px;
  }
}

select {
  -webkit-appearance: none;
  background-image: url(/assets/imgs/select-arrow-down.png);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  border-radius: 0;
}

// 시설의 이미지가 pw-img 컴포넌트 내에서 높이 100%로 채우게 변경
pw-img.full-height img {
  object-fit: cover;
  min-height: 100%;
}

// 시설의 이미지가 pw-img 컴포넌트 내에서 중앙에서 크기만큼 채우게 변경
pw-img.none-img img {
  object-fit: scale-down;
}

.fullscreen-modal {
  max-width: 100vw !important;
  width: 100vw;
  max-height: 100vh !important;
  height: 100vh;
  .mat-dialog-container {
    border-radius: 0;
    padding: 0;
  }

  #ifrWrapper {
    iframe {
      width: 100vw;
      height: 100vh;
    }
  }
}

.gm-style-iw {
  padding: 0 !important;
}

.gm-style-iw-d {
  overflow: unset !important;
}

.gm-ui-hover-effect {
  display: none !important;
}

.mat-form-field-invalid .mat-form-filed-label {
  color: rgba(0, 0, 0, 0.54) !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54) !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
  opacity: 0 !important;
  transform: unset !important;
  transition: unset !important;
}

[spacer] {
  width: 100%;
  height: 8px;
  background-color: $space-color;
}

.swiper-slide {
  height: unset;
  min-height: 100%;
}

.swiper-css-mode.mobile > .swiper-wrapper > .swiper-slide {
  padding-left: 16px;
  margin: 0 !important;
  &:first-child {
    padding-left: 16px;
  }
  &:last-child {
    padding-right: 16px;
  }
}

// 홈 공지사항 제목 innerHtml
.ntc-title {
  a {
    color: #333;
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }
}

.swiper-pagination-bullet {
  background-color: #fff;
  opacity: 0.5;
  border-radius: 50%;
  margin: 0 4px;
}

.swiper-pagination-bullet-active {
  background-color: mat.get-color-from-palette($theme-primary, 500);
  opacity: 1;
}
